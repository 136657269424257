import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { BehaviorSubject, lastValueFrom, throwError } from 'rxjs';
import { LoginUser, TokenModel } from '../models/login-user';
import { LanguageService } from '../dynamic/language.service';
import { ILinkedInScrappedData } from '../global.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public bsUserData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private readonly jwtToken = 'JWT_TOKEN';
  private readonly jwtRefreshToken = 'JWT_REFRESH_TOKEN';
  private readonly jwtTokenExpireIn = 'EXPIRES_IN';
  private readonly Timezone = 'TIMEZONE';

  isLoggedIn: boolean = false;

  constructor(
    private http: HttpClient,
    public ls: LanguageService,
  ) {
    this.isLoggedIn = !!localStorage.getItem('JWT_TOKEN');
  }

  // set login user details
  public get loginUserDetails(): LoginUser | null {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      return JSON.parse(userInfo);
    } else {
      return null;
    }
  }

  setUserData(user: any): void {
    this.bsUserData.next(user);
  }

  login(data: any, recaptcha: string | null): Promise<any> {
    return lastValueFrom(
      this.http.post<any>(`${environment.API_URL}login`, data, {
        headers: { recaptcha: recaptcha ?? '' },
      })
        .pipe(
          map(response => {
            this.isLoggedIn = true;
            this.userLogin(response);
            localStorage.setItem('userInfo', JSON.stringify(response.userData));
            return response.userData;
          }),
          catchError(error => {
            this.isLoggedIn = false;
            console.error('Login error:', error);
            throw error;
          }),
        ),
    );
  }

  promiscuousMode(data: ILinkedInScrappedData) {
    return lastValueFrom(this.http.post<any>(`${environment.API_URL}promiscuous-mode`, data));
  }

  private userLogin(data: any) {
    this.storeToken(data.token);
    this.setUserData(data.userData);
    return data.userData;
  }

  private storeToken(token: TokenModel) {
    if (!token) {
      return;
    }
    localStorage.setItem(this.jwtToken, token.accessToken);
    localStorage.setItem(this.jwtRefreshToken, token.refreshToken);
    localStorage.setItem(this.jwtTokenExpireIn, token.expiresIn);
    localStorage.setItem(this.Timezone, token.timezone);
  }

  refreshToken() {
    const refreshToken = localStorage.getItem(this.jwtRefreshToken);
    return this.http
      .get<any>(`${environment.API_URL}getRefreshToken/${refreshToken}`)
      .pipe(
        tap((response: any) => {
          const token: TokenModel = response['token'];
          this.setUserData(response['userData']);
          localStorage.setItem(this.jwtToken, token.accessToken);
          localStorage.setItem(this.jwtTokenExpireIn, token.expiresIn);
        }),
        mapTo(true),
        catchError(error => this.handleError(error)),
      );
  }

  private userLogout() {
    this.isLoggedIn = false;
    this.removeTokens();
    this.removeJwtToken();
    localStorage.removeItem('userInfo');
  }

  removeJwtToken() {
    this.removeTokens();
    this.setUserData(null);
  }

  private removeTokens() {
    localStorage.removeItem('userInfo');
    localStorage.removeItem(this.jwtToken);
    localStorage.removeItem(this.jwtRefreshToken);
    localStorage.removeItem(this.jwtTokenExpireIn);
    localStorage.removeItem(this.Timezone);
  }

  getJwtToken() {
    return localStorage.getItem(this.jwtToken);
  }

  logout(data: any) {
    return this.http
      .post<any>(`${environment.API_URL}logout`, data)
      .pipe(
        tap(() => {
          this.userLogout();
        }),
        mapTo(true),
        catchError(error => this.handleError(error)),
      );
  }

  private handleError(error: HttpErrorResponse | any) {
    let errorMsg = this.ls.data.toasts.unableToComplete;
    if (error instanceof HttpErrorResponse) {
      if (typeof error.error == 'string') {
        errorMsg = error.error;
      } else if (error.error.langCode) {
        errorMsg = this.ls.takeErrorMsg(error.error.langCode);
      } else {
        errorMsg = error.message;
      }
    }
    return throwError(errorMsg);
  }
}
