import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LanguageService } from 'src/app/dynamic/language.service';
import { CommonHelper, goToExternalLink } from 'src/app/services/common.helper';
import { CommonService } from 'src/app/services/common.service';
import { ThemesService } from 'src/app/themes/themes.service';
import { ESupportedThemes } from 'src/app/themes/themes.types';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less'],
})
export class SettingsComponent implements OnInit {

  @Output() logout: EventEmitter<boolean> = new EventEmitter<boolean>();

  confirmPopup: boolean = false;
  settingsLoader: boolean = false;
  avatarStyle: any;

  urlMappings: { [key: string]: string } = {
    'dashboard': '/dashboard',
    'saved-list': '/list',
    'integrations': '/settings/integrations',
    'edit-profile': '/profile/edit',
    'upgrade': '/settings/pricing',
    // Add more mappings as needed
  };

  redirectActions = [
    {
      title: 'Dashboard',
      url: 'dashboard',
      imgUrl: 'assets/images/svg/settings/dashboard.svg',
    },
    {
      title: 'Saved List',
      url: 'saved-list',
      imgUrl: 'assets/images/svg/settings/savedList.svg',
    },
    {
      title: 'Integrations',
      url: 'integrations',
      imgUrl: 'assets/images/svg/settings/integrations.svg',
    },
    // Add more actions as needed
  ];

  constructor(
    public commonService: CommonService,
    public themesService: ThemesService,
    public commonHelper: CommonHelper,
    public authService: AuthService,
    public ls: LanguageService) {
  }

  ngOnInit() {

    this.settingsLoader = true;
    setTimeout(() => {
      this.settingsLoader = false;
    }, 1000);

    this.getAvatarStyle();
    this.commonHelper.getDefaultCntList();
    this.commonHelper.getDefaultCmpList();
    this.commonHelper.getChExtCredits();
    this.commonHelper.getDefaultList();
    this.commonHelper.getProfileDetails();
    this.commonHelper.getSubscriptionInfo();
  }

  getAvatarStyle() {
    const avatarColorPair = this.commonHelper.preDefAvatarColors[Math.floor(Math.random() * this.commonHelper.preDefAvatarColors.length)];
    this.avatarStyle = {
      'background-color': avatarColorPair.background,
      'color': avatarColorPair.text,
    };
  }

  cancelLogout() {
    this.closePopup();
  }

  openPopup() {
    this.confirmPopup = true;
  }

  closePopup() {
    this.confirmPopup = false;
  }

  redirectToEmp(url: string) {
    if (this.urlMappings[url]) {
      goToExternalLink(environment.PRE_URL + '/auth/login?loaded=1&returnUrl=' + this.urlMappings[url], true);
    }
  }

  onListChange(event: any, type: 'contact' | 'company') {
    // creating new list
    if (event?.value) {
      const typeList: 'contacts' | 'companies' = type === 'contact' ? 'contacts' : 'companies';
      this.commonService.createList({ 'moduleType': 'mylist' }, typeList, type, event.value)
        .then();
    }

    // setting default list
    const request: any = {};
    const key: 'defaultListContactName' | 'defaultListCompanyName' = type === 'contact' ?
      'defaultListContactName' : 'defaultListCompanyName';
    request[key] = event?.label || '';

    this.commonService.saveSetting(request)
      .then((result: any) => {
        const defaultListName = type === 'contact' ? result?.defaultListContactName : result?.defaultListCompanyName;
        const targetList: 'defaultSelectedCntList' | 'defaultSelectedCmpList' = type === 'contact' ?
          'defaultSelectedCntList' : 'defaultSelectedCmpList';
        this.commonHelper.defaultList[targetList] = defaultListName || undefined;
      })
      .catch((_error: any) => {
        console.error('_error:', _error);
      });
  }

  changePosition(position: string) {
    const request: Object = {
      'position': position,
    };
    this.commonService.saveSetting(request)
      .then((result: any) => {
        this.commonHelper.pluginSettings.position = result?.position ? result?.position : 'right';
        this.commonHelper.postMessageToParent(this.commonHelper.pluginSettings);
        this.commonHelper.getSettings();
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  autoOpen(event: any) {
    const request: Object = {
      'isAutoOpen': !!event.target.checked,
    };
    this.commonService.saveSetting(request)
      .then((result: any) => {
        this.commonHelper.pluginSettings.open = result?.isAutoOpen ? result?.isAutoOpen : false;
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  whereToSave(event: any) {
    const request: Object = {
      'askWhereToSave': !!event.target.checked,
    };
    this.commonService.saveSetting(request)
      .then((result: any) => {
        this.commonHelper.pluginSettings.askWhereToSave = result?.askWhereToSave ? result?.askWhereToSave : false;
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  predictivEveryWhere(event: any) {
    const request: Object = {
      'isPredictivEveryWhere': !!event.target.checked,
    };
    this.commonService.saveSetting(request)
      .then((result: any) => {
        this.commonHelper.pluginSettings.everywhere = result?.isPredictivEveryWhere ? result?.isPredictivEveryWhere : false;
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  setTheme(value: string) {
    const request: Object = {
      'selectedTheme': value,
    };
    this.commonService.saveSetting(request)
      .then(() => {
        this.themesService.defineThemes(value as ESupportedThemes);
        this.commonHelper.getSettings();
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  confirmLogout() {
    this.logout.emit(true);
    this.authService.isLoggedIn = false;
  }

}
