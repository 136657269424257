<div class="main-body">

  <!-- company details -->
  <ng-container *ngIf="isCompanyAvailable && !isLoaded">
    <!-- profile -->
    <div class="custom-card">
      <div class="profile-card">
        <div class="saved-tag"
             *ngIf="commonHelper.isSaved.isCmpSaved"
        >
          <img class="vts-svg-green"
               src="assets/images/svg/saved-tag.svg"
               alt="saved"
          >
        </div>
        <div class="profile-top">
          <div class="compLogo">
            <img [src]="this.commonHelper.pDetails.cmpLogo ? this.commonHelper.pDetails.cmpLogo :
                this.linkedInScrappedData.contact.experience[0].companyLogoUrl"
                 alt="companyLogo"
            >
          </div>
          <div class="profile-content">
            <div class="cnt-desc">
              <div class="name">
                <h6 #companyName
                    class="overflow-text"
                    [tooltip]="companyName.offsetWidth < companyName.scrollWidth ? commonHelper.pDetails.company_name :''"
                    placement="bottom"
                >
                  {{ commonHelper.pDetails.company_name }}
                </h6>
              </div>
              <div class="action">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt="redirect"
                       (click)="goToCompDetails(commonHelper.pDetails.companyId)"
                  >
                </div>
                <div class="addList">
                  <img class="vts-svg-primary cursor"
                       [src]="!commonHelper.isSaved.isCmpSaved ? 'assets/images/svg/addList.svg' :
                          'assets/images/svg/addList-fill.svg'"
                       alt="addList"
                       (click)="addToList()"
                       [tooltip]="ls.data.company.toolTip.addToList"
                       placement="top"
                  >
                  <div class="vts-border-theme save-contact"
                       *ngIf="isCmpListAdded"
                  >
                    <div class="form-group">
                      <ng-select #cmpListSelect
                                 [items]="commonHelper.cmpListData.cmpListDataAvl"
                                 bindLabel="value"
                                 bindValue="value"
                                 [addTag]="commonHelper.addList"
                                 [addTagText]="ls.data.company.createNewList"
                                 [multiple]="true" [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                 [(ngModel)]="commonHelper.selectedListType.selectedCmpList"
                                 (change)="commonHelper.onListChange('company')"
                      >
                      </ng-select>
                    </div>
                    <div class="actionButtons">
                      <span class="vts-theme-bg vts-border-theme fill-btn"
                            (click)="saveCompanyList(commonHelper.selectedListType.selectedCmpList)"
                      >
                        {{ ls.data.global.buttons.save }}
                      </span>
                      <span class="vts-border-theme vts-icon-color"
                            (click)="onListCancel('company')"
                      >
                        {{ ls.data.global.buttons.cancel }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p #parentDept class="cmpDesc"
               [tooltip]="parentDept.offsetWidth < parentDept.scrollWidth ? commonHelper.pDetails.parentDept :''"
               placement="bottom"
            >
              {{
                commonHelper.pDetails.parentDept ? commonHelper.pDetails.parentDept :
                  ls.data.global.misc.notAvailable
              }}
            </p>
          </div>
        </div>
        <div class="profile-bottom">
          <p class="cmpDesc cmpDetails"
             *ngIf="commonHelper.pDetails.company_description"
          >
            {{
              commonHelper.isExpanded.open ? commonHelper.pDetails.company_description :
                commonHelper.isExpanded.open ? commonHelper.pDetails.company_description :
                  commonHelper.pDetails.company_description.slice(0, 100)
            }}
            <a class="vts-icon-color textToExpand"
               (click)="commonHelper.toggleDescription()"
               *ngIf="commonHelper.pDetails.company_description !== '' && commonHelper.pDetails.company_description.length > 100"
            >
              {{
                commonHelper.isExpanded.open ? ls.data.global.misc.viewLess : ls.data.global.misc.viewMore
              }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <!-- description -->
    <div class="custom-card">
      <div class="profile-card">
        <div class="cmpProfile-bottom">
          <div class="vts-tag-color panel-title">
            {{ ls.data.company.companyInfo }}
          </div>
          <div class="viewComDetails">
            <div class="cmp-details-list">
              <div class="cmp-list-item">
                <div class="infographics ">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/copy.svg"
                       alt="about"
                  >
                </div>
                <div class="infoDesc">
                  <span class="vts-icon-color cmp-name"
                        (click)="goToExternalLink(commonHelper.pDetails.website,false)"
                  >
                    {{ commonHelper.pDetails.website }}
                  </span>
                  <span *ngIf="commonHelper.pDetails.company_li_url">
                    |
                    <img src="assets/images/svg/linkedin.svg"
                         alt="linkedin"
                         (click)="goToExternalLink(commonHelper.pDetails.company_li_url,false)"
                    >
                  </span>
                  <span *ngIf="commonHelper.pDetails.company_facebook_url">
                    |
                    <img src="assets/images/svg/facebook.svg"
                         alt="facebook"
                         (click)="goToExternalLink(commonHelper.pDetails.company_facebook_url,false)"
                    >
                  </span>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/founded.svg"
                       alt="founded"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.foundIn }}
                  </span>
                  <p class="valCount">
                    {{ commonHelper.pDetails.founded }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/headquarter.svg"
                       alt="headquarter"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.headquatersLocation }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.hq_location ? commonHelper.pDetails.hq_location :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/empSize.svg"
                       alt="empSize"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.employeeSize }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.emp_range ? commonHelper.pDetails.emp_range :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/revenue.svg"
                       alt="revenue"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.annualRevenueRange }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.rev_range ? commonHelper.pDetails.rev_range :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/industry.svg"
                       alt="industry"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.idustry }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.parentDept ? commonHelper.pDetails.parentDept :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/specialities.svg"
                       alt="specialities"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.specialities }}
                  </span>
                  <div class="pChipSets">
                    <ng-container *ngIf="commonHelper.pDetails.specialty.length">
                      <div class="vts-chipset pChipSet"
                           *ngFor="let item of commonHelper.pDetails.specialty"
                      >
                        {{ item }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!commonHelper.pDetails.specialty.length">
                      <p class="valCount">
                        {{ ls.data.global.misc.notAvailable }}
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- key employees -->
    <div class="custom-card mb-0">
      <div class="profile-card"
           *ngIf="commonHelper.employeeData.cntData && commonHelper.employeeData.cntData.length > 0"
      >
        <div class="empProfile-bottom">
          <div class="card-title">
            <div class="vts-tag-color panel-title">
              {{ ls.data.company.keyEmployees }}
            </div>
            <div class="vts-icon-color link"
                 (click)="redirectToEmp('employee')"
            >
              {{ ls.data.company.showEmployees }}
            </div>
          </div>
          <div class="viewEmpDetails">
            <div class="emp-details-list">
              <div *ngFor="let employee of commonHelper.employeeData.cntData;let i = index"
                   class="emp-list-item"
              >
                <div class="emp-list-top">
                  <div class="emp-list-top-left">
                    <div class="employee-logo">
                      <ng-container *ngIf="employee.imageUrl; else initials">
                        <img [src]="employee.imageUrl"
                             alt="company-logo"
                        >
                      </ng-container>
                      <ng-template #initials>
                        <div class="initials"
                             [ngStyle]="employee.avatarStyle"
                        >
                          {{ commonHelper.getInitials(employee.fullName) }}
                        </div>
                      </ng-template>
                    </div>
                    <div class="employee-info">
                      <div class="employee-title">
                        <h4 #firstName
                            class="vts-icon-color overflow-text"
                            [tooltip]="firstName.offsetWidth < firstName.scrollWidth? employee.fullName :''"
                            placement="bottom"
                            (click)="goToCntDetails(employee.contactId)"
                        >
                          {{ employee.fullName }}
                        </h4>
                        <ng-container *ngIf="employee?.contact_li_url">
                          <img class="social_icon" src="assets/images/svg/linkedin.svg"
                               alt="linkedin"
                               (click)="goToExternalLink(employee.contact_li_url,false)"
                          >
                        </ng-container>
                        <ng-container *ngIf="employee?.facebook_url">
                          <img class="social_icon"
                               src="assets/images/svg/facebook.svg"
                               alt="facebook"
                               (click)="goToExternalLink(employee.facebook_url,false)"
                          >
                        </ng-container>
                      </div>
                      <p #jobTitleEle
                         class="overflow-text"
                         [tooltip]="jobTitleEle.offsetWidth < jobTitleEle.scrollWidth ? employee.jobTitle :''"
                         placement="bottom"
                      >
                        {{ employee.jobTitle ? employee.jobTitle : "" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="emp-list-bottom">
                  <div class="emp-list-bottom-left">
                    <div class="vts-green-color-border empDetails">
                      <ul [ngClass]="{'viewed': employee.alreadyView}">
                        <li>
                          {{ ls.data.company.workEmail }}
                        </li>
                        <li>
                          {{ ls.data.company.phoneOffice }}
                        </li>
                        <li>
                          {{ ls.data.company.directPhone }}
                        </li>
                      </ul>
                      <span class="lock">
                        <i *ngIf="!employee.alreadyView"
                           class="vts-theme-bg fa fa-lock"
                           [ngClass]="{'disabled':!employee.revealContact}"
                           aria-hidden="true"
                           (click)="handleContactView(employee.contactId)"
                        ></i>
                        <i *ngIf="employee.alreadyView"
                           [ngClass]="{'vts-green-color-bg viewed':employee.alreadyView}"
                           class="fa fa-unlock-alt"
                           aria-hidden="true"
                        ></i>
                      </span>
                      <span class="isLock"
                            *ngIf="employee.alreadyView"
                      >
                        <i *ngIf="employee.isContactCollapse"
                           [ngClass]="{'vts-red-color-bg viewed min':employee.alreadyView}"
                           (click)="handleContactView(employee.contactId,employee.isContactCollapse)"
                           class="fa fa-minus"
                           aria-hidden="true"
                        ></i>
                        <i *ngIf="!employee.isContactCollapse"
                           [ngClass]="{'vts-green-color-bg viewed max':employee.alreadyView}"
                           (click)="handleContactView(employee.contactId,employee.isContactCollapse)"
                           class="fa fa-angle-down" aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    <div class="empDetailsView"
                         *ngIf="employee.isContactCollapse && contactData"
                    >
                      <ul>
                        <li>
                          <div class="label">
                            {{ ls.data.company.workEmail }}
                          </div>
                          <div #emailEle
                               class="value"
                               [tooltip]="emailEle.offsetWidth < emailEle.scrollWidth ? contactData.emailAddress :''"
                               placement="top"
                          >
                            :
                            {{
                              contactData.emailAddress ? contactData.emailAddress :
                                ls.data.global.misc.notAvailable
                            }}
                          </div>
                        </li>
                        <li>
                          <div class="label">
                            {{ ls.data.company.phoneOffice }}
                          </div>
                          <div class="value">
                            :
                            {{
                              contactData.phoneOffice ? contactData.phoneOffice :
                                ls.data.global.misc.notAvailable
                            }}
                          </div>
                        </li>
                        <li>
                          <div class="label">
                            {{ ls.data.company.directPhone }}
                          </div>
                          <div class="value">
                            :
                            {{
                              contactData.phoneDirect ? contactData.phoneDirect :
                                ls.data.global.misc.notAvailable
                            }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="emp-list-bottom-right">
                    <div class="action">
                      <div class="addList">
                        <img class="vts-svg-primary cursor"
                             [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                             alt="addList"
                             (click)="getCntList(employee.contactId,i,employee.companyId)"
                             [tooltip]="ls.data.company.toolTip.addToList"
                             placement="top"
                        >
                        <div class="vts-border-theme save-contact"
                             *ngIf="isCntListAdded[i]"
                        >
                          <div class="form-group">
                            <ng-select #cntListSelect
                                       [items]="commonHelper.cntListData.cntListDataAvl"
                                       bindLabel="value"
                                       bindValue="value"
                                       [addTag]="commonHelper.addList"
                                       [addTagText]="ls.data.company.createNewList" [multiple]="true"
                                       [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                       [(ngModel)]="commonHelper.selectedListType.selectedCntList"
                                       (change)="commonHelper.onListChange('contact')"
                            ></ng-select>
                          </div>
                          <div class="actionButtons">
                            <span class="vts-theme-bg vts-border-theme fill-btn"
                                  (click)="saveEmpContactList(commonHelper.selectedListType.selectedCntList,i,employee.contactId,employee.companyId)"
                            >
                              {{ ls.data.global.buttons.save }}
                            </span>
                            <span class="vts-border-theme vts-icon-color"
                                  (click)="onListCancel('contact',i)"
                            >
                              {{ ls.data.global.buttons.cancel }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="redirectEmp text-center">
        <div class="redirect-pointer"
             (click)="goToCompDetails(commonHelper.employeeData.cntData[0].companyId)"
        >
          <strong>
            {{ ls.data.company.moreCompanyDetails }}
          </strong>
          <img class="vts-svg-primary"
               src="assets/images/svg/redirect.svg"
               alt="redirect"
          >
        </div>
      </div>
    </div>
  </ng-container>

  <!-- company not found -->
  <ng-container *ngIf="!isCompanyAvailable && !isLoaded">
    <div class="custom-card mb-0 customHeight">
      <div class="profile-card">
        <div class="profile-top cmpProfileTop">
          <div class="userLogo cmpLogo">
            <img
              [src]="linkedInScrappedData.company.companyLogoUrl ? linkedInScrappedData.company.companyLogoUrl :
                linkedInScrappedData.contact.experience[0].companyLogoUrl ? linkedInScrappedData.contact.experience[0].companyLogoUrl:'assets/images/defaultCompany.png'"
              alt="companyLogo"
            >
          </div>
          <div class="profile-content">
            <div class="cnt-desc">
              <div class="name">
                <h6 #CmpName
                    class="overflow-text"
                    [tooltip]="CmpName.offsetWidth < CmpName.scrollWidth ? linkedInScrappedData.company.companyName ||
                      linkedInScrappedData.contact.experience[0].companyName :''"
                    placement="bottom"
                >
                  {{ linkedInScrappedData.company.companyName || linkedInScrappedData.contact.experience[0].companyName }}
                </h6>
              </div>
              <div class="action no-cursor">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt="redirect"
                  >
                </div>
                <div class="addList">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/addList.svg"
                       alt="addList"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cmpProfile-bottom">
          <div class="vts-border-theme notFound text-center">
            <img class="vts-svg-primary"
                 src="assets/images/not-found.svg"
                 alt="not-found"
            >
            <p>
              {{ ls.data.company.noCompanyDataFoundYetWe }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- skeleton -->
  <ng-container *ngIf="isLoaded">
    <div class="container p-0">
      <div class="row">
        <div class="skeleton">
          <div class="custom-skeleton">
            <div class="p-d-flex p-mb-3">
              <div class="circle skeletonEffect p-mr-2"
                   size="4rem"
              ></div>
              <div class="stripes">
                <div class="p-mb-2 skeletonEffect"></div>
                <div class="p-mb-2 skeletonEffect"></div>
              </div>
            </div>
            <div class="stripes fullStripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
          <div class="custom-skeleton">
            <div class="stripes full2Stripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>

              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>
