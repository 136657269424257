import { ILang, ISupportedLang } from './lang.pack';
import * as Languages from './languages';

export class LanguageService {

  public data: ILang = Languages.LEnglish;

  constructor() {
  }

  public defineLanguage(definedLanguage: ISupportedLang) {
    switch (definedLanguage) {
    case ISupportedLang.ENGLISH: {
      this.data = Languages.LEnglish;
      console.info(`${definedLanguage} selected`);
      break;
    }
    default: {
      console.error('Error while using ', definedLanguage);
    }
    }
  }

  public takeErrorMsg(errorName: string) {
    // @ts-ignore
    const res: string | undefined = this.data.errors[errorName];
    return res ?? this.data.errors.placeholder;
  }

  public takeSuccessMsg(success: string) {
    // @ts-ignore
    const res: string | undefined = this.data.success[success];
    return res ?? this.data.success.placeholder;
  }

}
