import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { GlobalHelper } from '../global.helper';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    public globalHelper: GlobalHelper,
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any> | HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> | any {
    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }
    return next.handle(request)
      .pipe(
        catchError(
          (exception: HttpErrorResponse) => {
            if (request.url.includes('getRefreshToken') || request.url.includes('linkedin')) {
              if (exception instanceof HttpErrorResponse && exception.status === 401) {
                let clearInterval = setTimeout(() => {
                  let browserDetails = this.globalHelper.getBrowserNameAndVersion();
                  let browserName = browserDetails.name || 'other';
                  let body = { browser: browserName };
                  this.authService.logout(body)
                    .subscribe((_: any) => {
                      this.isRefreshing = false;
                      clearTimeout(clearInterval);
                    });
                });
              }
            }
            if (exception instanceof HttpErrorResponse && exception.status === 401) {
              return this.handle401Error(request, next);
            } else {
              if (exception instanceof HttpErrorResponse && exception.status === 400) {
                return throwError(exception);
              }
              if (exception instanceof HttpErrorResponse && exception.status === 500) {
                return throwError(
                  new HttpErrorResponse({
                    error: 'Unexpected error occured',
                    headers: exception.headers,
                    status: exception.status,
                    statusText: exception.statusText,
                    url: exception.url ?? undefined,
                  }),
                );
              }
              if (exception instanceof HttpErrorResponse && exception.status === 404) {
                return throwError(
                  new HttpErrorResponse({
                    error: 'Requested service currently not working',
                    headers: exception.headers,
                    status: exception.status,
                    statusText: exception.statusText,
                    url: exception.url ?? undefined,
                  }),
                );
              }
              return throwError(
                new HttpErrorResponse({
                  headers: exception.headers,
                  status: exception.status,
                  statusText: exception.statusText,
                  url: exception.url ?? undefined,
                  error: exception.error,
                }),
              );
            }
          },
        ),
      );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken: string | null = localStorage.getItem('JWT_REFRESH_TOKEN');
      if (!refreshToken) {
        this.authService.logout({ browser: this.globalHelper.getBrowserNameAndVersion().name })
          .subscribe(() => {
            this.isRefreshing = false;
          });
        return throwError(new Error('No JWT'));
      }
      return this.authService.refreshToken()
        .pipe(
          switchMap(
            (response: any) => {
              this.isRefreshing = false;
              const token = response['token'];
              this.refreshTokenSubject.next(token.accessToken);
              return next.handle(this.addToken(request, token.accessToken));
            }), catchError((error: any) => {
            this.authService.logout({ browser: this.globalHelper.getBrowserNameAndVersion().name })
              .subscribe(() => {
                this.isRefreshing = false;
              });
            return throwError(new Error(error));
          }));
    }
    return this.refreshTokenSubject.pipe(
      filter((token: any) => token !== null),
      take(1),
      switchMap((jwt: any) => next.handle(this.addToken(request, jwt))),
    );
  }

  private addToken(request: HttpRequest<any>, token: string | null) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
      },
    });
  }
}
