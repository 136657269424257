<div class="main-body">
  <ng-container *ngIf="isContactAvailable && !isLoaded">
    <!-- contact card -->
    <div class="custom-card">
      <div class="profile-card">
        <div class="saved-tag"
             *ngIf="commonHelper.isSaved.isCntSaved"
        >
          <img src="assets/images/svg/saved-tag.svg"
               alt="saved"
          >
        </div>
        <div class="profile-top">
          <div class="userLogo">
            <ng-container *ngIf="commonHelper.pDetails.imageUrl && commonHelper.pDetails.imageUrl !==
                  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; else initials">
              <img [src]="commonHelper.pDetails.imageUrl"
                   alt="user-ogo"
              ></ng-container>
            <ng-template #initials>
              <div class="initials"
                   [ngStyle]="commonHelper.pDetails.avatarStyle"
              >
                {{ commonHelper.getInitials(commonHelper.pDetails.fullName) }}
              </div>
            </ng-template>
          </div>
          <div class="profile-content">
            <div class="cnt-desc">
              <div class="name">
                <h6 #fullNameEle
                    class="overflow-text"
                    [tooltip]="fullNameEle.offsetWidth < fullNameEle.scrollWidth ? commonHelper.pDetails.fullName :''"
                    placement="top"
                >
                  {{ commonHelper.pDetails.fullName }}
                </h6>
              </div>
              <div class="action">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src=" assets/images/svg/redirect.svg"
                       alt="redirect"
                       (click)="goToCntDetails(commonHelper.pDetails.contactId)"
                  >
                </div>
                <div class="addList">
                  <img class="vts-svg-primary cursor"
                       [src]="!commonHelper.isSaved.isCntSaved ? 'assets/images/svg/addList.svg' :
                          'assets/images/svg/addList-fill.svg'"
                       alt="addList"
                       (click)="getCntList()"
                       tooltip="Add to list"
                       placement="top"
                  >
                  <div class="vts-border-theme save-contact"
                       *ngIf="isCntListAdded"
                  >
                    <div class="form-group">
                      <ng-select #cntListSelect
                                 [items]="commonHelper.cntListData.cntListDataAvl"
                                 bindLabel="value"
                                 bindValue="value"
                                 [addTag]="commonHelper.addList"
                                 [addTagText]="ls.data.company.createNewList"
                                 [multiple]="true" [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                 [(ngModel)]="commonHelper.selectedListType.selectedCntList"
                                 (change)="commonHelper.onListChange('contact')"
                      ></ng-select>
                    </div>
                    <div class="actionButtons">
                      <span class="vts-theme-bg vts-border-theme fill-btn"
                            (click)="saveContactList(commonHelper.selectedListType.selectedCntList)"
                      >
                        {{ ls.data.global.buttons.save }}
                      </span>
                      <span class="vts-border-theme vts-icon-color"
                            (click)="onListCancel('contact')"
                      >
                        {{ ls.data.global.buttons.cancel }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p #jobTitleEle
               class="job-title overflow-text"
               [tooltip]="commonHelper.pDetails.job_title == 'Not Available' ? '' :
                    jobTitleEle.offsetWidth < jobTitleEle.scrollWidth ? commonHelper.pDetails.job_title : ''"
               placement="bottom"
            >
              {{
                commonHelper.pDetails.job_title ? commonHelper.pDetails.job_title :
                  ls.data.global.misc.notAvailable
              }}
            </p>
          </div>
        </div>
        <div class="profile-bottom">
          <div class="handleContactView">
            <div class="vts-theme-button customBtn disabled"
                 [ngClass]="{'disabled' : isContactView ||
                    (commonHelper.pDetails.work_email == 'Not Available' &&
                    commonHelper.pDetails.phone_office == 'Not Available' &&
                    commonHelper.pDetails.phone_direct == 'Not Available')}"
                 (click)="viewOfficeEmail('office_email')"
            >
              {{ ls.data.contact.showContact }}
            </div>
          </div>
          <div class="viewContDetails">
            <div class="cnt-details-list">
              <div class="cnt-details">
                <div class="vts-background-color cnt-title">
                  {{ ls.data.contact.officeEmail }}
                </div>
                <div class="cnt-data">
                  <div class="checkMark"
                       *ngIf="isFaCheckFaCopyShowEM"
                  >
                    <img class="vts-svg-green"
                         src=" assets/images/svg/checked.svg"
                         alt="redirect"
                    >
                  </div>
                  <div class="pDetails">
                    <p #workEmailCopy
                       [tooltip]="commonHelper.pDetails.work_email == 'Not Available' ?  '' :
                       (isContactView ? (workEmailCopy.offsetWidth < workEmailCopy.scrollWidth ?
                       commonHelper.pDetails.work_email : '') : ls.data.company.oneViewIsRequire )"
                       placement="bottom"
                       class="overflow-text overflow-EmailText"
                    >
                      {{
                        commonHelper.pDetails.work_email ? commonHelper.pDetails.work_email :
                          ls.data.global.misc.notAvailable
                      }}
                    </p>
                  </div>
                  <div class="copyIco"
                       *ngIf="isFaCheckFaCopyEM && isContactView && commonHelper.pDetails.work_email != 'Not Available'"
                  >
                    <i class="vts-hover-color fa fa-files-o"
                       aria-hidden="true"
                       [tooltip]="ls.data.global.buttons.copy"
                       placement="bottom"
                       (click)='copyToClipboard(workEmailCopy, "Office Email")'
                    ></i>
                  </div>
                </div>
              </div>
              <div class="cnt-details">
                <div class="vts-background-color cnt-title">
                  {{ ls.data.contact.officePhone }}
                </div>
                <div class="cnt-data">
                  <div class="checkMark"
                       *ngIf="isFaCheckFaCopyShowPO"
                  >
                    <img class="vts-svg-green"
                         src=" assets/images/svg/checked.svg"
                         alt="redirect"
                    >
                  </div>
                  <div class="pDetails">
                    <p #phoneOfficeCopy
                       [tooltip]="commonHelper.pDetails.phone_office == 'Not Available' ?  '' :
                       (isContactView ? (phoneOfficeCopy.offsetWidth < phoneOfficeCopy.scrollWidth ?
                       commonHelper.pDetails.phone_office : '') : ls.data.company.oneViewIsRequire )"
                       placement="bottom"
                       class="overflow-text"
                    >
                      {{
                        commonHelper.pDetails.phone_office ? commonHelper.pDetails.phone_office :
                          ls.data.global.misc.notAvailable
                      }}
                    </p>
                  </div>
                  <div class="copyIco"
                       *ngIf="isFaCheckFaCopyPO && isContactView && commonHelper.pDetails.phone_office != 'Not Available'"
                  >
                    <i class="vts-hover-color fa fa-files-o"
                       aria-hidden="true"
                       [tooltip]="ls.data.global.buttons.copy"
                       placement="bottom"
                       (click)='copyToClipboard(phoneOfficeCopy, "Office Phone")'
                    ></i>
                  </div>
                </div>
              </div>
              <div class="cnt-details">
                <div class="vts-background-color cnt-title">
                  {{ ls.data.company.directPhone }}
                </div>
                <div class="cnt-data">
                  <div class="checkMark"
                       *ngIf="isFaCheckFaCopyShowPD"
                  >
                    <img class="vts-svg-green"
                         src=" assets/images/svg/checked.svg"
                         alt="redirect"
                    >
                  </div>
                  <div class="pDetails">
                    <p #phoneDirectCopy
                       [tooltip]="commonHelper.pDetails.phone_direct == 'Not Available' ?  '' :
                          (isContactView ? (phoneDirectCopy.offsetWidth < phoneDirectCopy.scrollWidth ?
                          commonHelper.pDetails.phone_direct : '') : ls.data.company.oneViewIsRequire)"
                       placement="bottom" class="overflow-text">
                      {{
                        commonHelper.pDetails.phone_direct ? commonHelper.pDetails.phone_direct :
                          ls.data.global.misc.notAvailable
                      }}
                    </p>
                  </div>
                  <div class="copyIco"
                       *ngIf="isFaCheckFaCopyShowPD && isContactView &&
                          commonHelper.pDetails.phone_direct != 'Not Available'"
                  >
                    <i class="vts-hover-color fa fa-files-o"
                       aria-hidden="true"
                       [tooltip]="ls.data.global.buttons.copy"
                       placement="bottom"
                       (click)='copyToClipboard(phoneDirectCopy, "Direct Phone")'
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- company card -->
    <div class="custom-card mb-0">
      <div class="profile-card">
        <div class="profile-top cmpProfileTop">
          <div class="userLogo cmpLogo">
            <img [src]="commonHelper.pDetails.cmpLogo ?
                        commonHelper.pDetails.cmpLogo : 'assets/images/defaultCompany.png'"
                 alt="companyLogo"
            >
          </div>
          <div class="profile-content">
            <div class="cnt-desc">
              <div class="name">
                <h6 #companyName
                    class="overflow-text"
                    [tooltip]="companyName.offsetWidth < companyName.scrollWidth ?
                               commonHelper.pDetails.company_name :''"
                    placement="bottom"
                >
                  {{ commonHelper.pDetails.company_name }}
                </h6>
              </div>
              <div class="action">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt="redirect"
                       (click)="goToCmpDetails(commonHelper.pDetails.companyId)"
                  >
                </div>
                <div class="addList">
                  <img class="vts-svg-primary cursor"
                       [src]="!commonHelper.isSaved.isCmpSaved ? 'assets/images/svg/addList.svg' :
                            'assets/images/svg/addList-fill.svg'"
                       alt="addList" (click)="addToList()"
                       [tooltip]="ls.data.company.toolTip.addToList"
                       placement="top"
                  >
                  <div class="vts-border-theme save-contact"
                       *ngIf="isCmpListAdded"
                  >
                    <div class="form-group">
                      <ng-select #cmpListSelect
                                 [items]="commonHelper.cmpListData.cmpListDataAvl"
                                 bindLabel="value"
                                 bindValue="value"
                                 [addTag]="commonHelper.addList"
                                 [addTagText]="ls.data.company.createNewList"
                                 [multiple]="true" [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                 [(ngModel)]="commonHelper.selectedListType.selectedCmpList"
                                 (change)="commonHelper.onListChange('company')"
                      ></ng-select>
                    </div>
                    <div class="actionButtons">
                      <span class="vts-theme-bg vts-border-theme fill-btn"
                            (click)="saveCompanyList(commonHelper.selectedListType.selectedCmpList)"
                      >
                        {{ ls.data.global.buttons.save }}
                      </span>
                      <span class="vts-border-theme vts-icon-color"
                            (click)="onListCancel('company')"
                      >
                        {{ ls.data.global.buttons.cancel }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cmpProfile-bottom">
          <div class="viewComDetails">
            <div class="cmp-details-list">
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/copy.svg"
                       alt="about"
                  >
                </div>
                <div class="infoDesc">
                  <span class="vts-icon-color cmp-name"
                        (click)="goToExternalLink(commonHelper.pDetails.website,false)"
                  >
                    {{ commonHelper.pDetails.website }}
                  </span>
                  <span *ngIf="commonHelper.pDetails.company_li_url">
                    |
                    <img src="assets/images/svg/linkedin.svg"
                         alt="linkedin"
                         (click)="goToExternalLink(commonHelper.pDetails.company_li_url,false)"
                    >
                  </span>
                  <span *ngIf="commonHelper.pDetails.company_facebook_url">
                    |
                    <img src="assets/images/svg/facebook.svg"
                         alt="facebook"
                         (click)="goToExternalLink(commonHelper.pDetails.company_facebook_url,false)"
                    >
                  </span>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/about.svg"
                       alt="about"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.contact.about }}
                  </span>
                  <p class="cmpDesc cmpDetails"
                     *ngIf="commonHelper.pDetails.company_description"
                  >
                    {{
                      commonHelper.isExpanded.open ? commonHelper.pDetails.company_description :
                        commonHelper.isExpanded.open ? commonHelper.pDetails.company_description :
                          commonHelper.pDetails.company_description.slice(0, 100)
                    }}
                    <a class="vts-icon-color textToExpand"
                       (click)="commonHelper.toggleDescription()"
                       *ngIf="commonHelper.pDetails.company_description !== '' && commonHelper.pDetails.company_description.length > 100"
                    >
                      {{
                        commonHelper.isExpanded.open ? ls.data.global.misc.viewLess :
                          ls.data.global.misc.viewMore
                      }}
                    </a>
                  </p>
                  <p class="cmpDesc cmpDetails"
                     *ngIf="!commonHelper.pDetails.company_description"
                  >
                    {{ ls.data.global.misc.notAvailable }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/founded.svg"
                       alt="founded"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.foundIn }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.founded ? commonHelper.pDetails.founded :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/headquarter.svg"
                       alt="headquarter"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.headquatersLocation }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.hq_location ? commonHelper.pDetails.hq_location :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/empSize.svg"
                       alt="empSize"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.employeeSize }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.emp_range ? commonHelper.pDetails.emp_range :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
                <div class="vts-icon-color valCount showEmployees"
                     (click)="redirectToEmp('employee')"
                >
                  {{ ls.data.company.showEmployees }}
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/revenue.svg"
                       alt="revenue"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.annualRevenueRange }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.rev_range ? commonHelper.pDetails.rev_range :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/industry.svg"
                       alt="industry"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.idustry }}
                  </span>
                  <p class="valCount">
                    {{
                      commonHelper.pDetails.parentDept ? commonHelper.pDetails.parentDept :
                        ls.data.global.misc.notAvailable
                    }}
                  </p>
                </div>
              </div>
              <div class="cmp-list-item">
                <div class="infographics">
                  <img class="vts-svg-secondary"
                       src="assets/images/svg/cmp/specialities.svg"
                       alt="specialities"
                  >
                </div>
                <div class="vts-tag-color infoDesc">
                  <span>
                    {{ ls.data.company.specialities }}
                  </span>
                  <div class="pChipSets">
                    <ng-container *ngIf="commonHelper.pDetails.specialty.length">
                      <div class="vts-chipset pChipSet"
                           *ngFor="let item of commonHelper.pDetails.specialty"
                      >
                        {{ item }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!commonHelper.pDetails.specialty.length">
                      <p class="valCount">
                        {{ ls.data.global.misc.notAvailable }}
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="redirectEmp text-center">
            <div (click)="goToCntDetails(commonHelper.pDetails.contactId)"
                 class="redirect-pointer"
            >
              <strong>
                {{ ls.data.contact.moreContactDetails }}
              </strong>
              <img class="vts-svg-primary"
                   src="assets/images/svg/redirect.svg"
                   alt="redirect"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- contact not found  -->
  <ng-container *ngIf="!isContactAvailable && !isLoaded">
    <div class="custom-card">
      <div class="profile-card">
        <div class="profile-top">
          <div class="userLogo">
            <img
              [src]="linkedInScrappedData.contact.contactImageUrl && linkedInScrappedData.contact.contactImageUrl !==
                  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' ?
                linkedInScrappedData.contact.contactImageUrl : 'assets/images/profile-image.png'"
              alt="userLogo"
            >
          </div>
          <div class="profile-content">
            <div class="cnt-desc">
              <div class="name">
                <h6 #fullNameScrapEle
                    class="overflow-text"
                    [tooltip]="fullNameScrapEle.offsetWidth < fullNameScrapEle.scrollWidth ?
                    linkedInScrappedData.contact.contactName :''"
                    placement="top"
                >
                  {{ linkedInScrappedData.contact.contactName ? linkedInScrappedData.contact.contactName : 'Not Available' }}
                </h6>
              </div>
              <div class="action no-cursor">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src=" assets/images/svg/redirect.svg"
                       alt="redirect"
                  >
                </div>
                <div class="addList">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/addList.svg"
                       alt="addList"
                  >
                </div>
              </div>
            </div>
            <p #JTitle class="job-title overflow-text"
               [tooltip]="linkedInScrappedData.contact.experience[0].jobTitle == 'Not Available' ? '' : JTitle.offsetWidth < JTitle.scrollWidth ? linkedInScrappedData.contact.experience[0].jobTitle : ''"
               placement="bottom"
            >
              {{ linkedInScrappedData.contact.experience[0].jobTitle ? linkedInScrappedData.contact.experience[0].jobTitle : ls.data.global.misc.notAvailable }}
            </p>
          </div>
        </div>
        <div class="profile-bottom">
          <div class="handleContactView">
            <div class="vts-theme-button customBtn disabled"
            >{{ ls.data.contact.showContact }}
            </div>
          </div>
          <div class="viewContDetails">
            <div class="cnt-details-list">
              <div class="cnt-details">
                <div class="cnt-title">
                  {{ ls.data.contact.officeEmail }}
                </div>
                <div class="cnt-data">
                  <div class="pDetails">
                    <p>
                      {{ ls.data.contact.stayTunedDataCommingSoon }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="cnt-details">
                <div class="cnt-title">
                  {{ ls.data.contact.officePhone }}
                </div>
                <div class="cnt-data">
                  <div class="pDetails">
                    <p>
                      {{ ls.data.contact.stayTunedDataCommingSoon }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-card mb-0 customHeight">
      <div class="profile-card">
        <div class="profile-top cmpProfileTop">
          <div class="userLogo cmpLogo">
            <img
              [src]="this.linkedInScrappedData.contact.experience[0].companyLogoUrl ?
                this.linkedInScrappedData.contact.experience[0].companyLogoUrl : 'assets/images/defaultCompany.png'"
              alt="companyLogo"
            >
          </div>
          <div class="profile-content">
            <div class="cnt-desc">
              <div class="name">
                <h6 #companyname
                    class="overflow-text"
                    [tooltip]="companyname.offsetWidth < companyname.scrollWidth ? linkedInScrappedData.contact.experience[0].companyName :''"
                    placement="bottom"
                >
                  {{ linkedInScrappedData.contact.experience[0].companyName }}
                </h6>
              </div>
              <div class="action no-cursor">
                <div class="redirect">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/redirect.svg"
                       alt="redirect"
                  >
                </div>
                <div class="addList">
                  <img class="vts-svg-primary"
                       src="assets/images/svg/addList.svg"
                       alt="addList"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cmpProfile-bottom">
          <div class="vts-border-theme notFound text-center">
            <img class="vts-svg-primary"
                 src="assets/images/not-found.svg"
                 alt="not-found"
            >
            <p>
              {{ ls.data.company.noCompanyDataFoundYetWe }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- skeleton -->
  <ng-container *ngIf="isLoaded">
    <div class="container">
      <div class="row">
        <div class="skeleton">
          <div class="custom-skeleton">
            <div class="p-d-flex p-mb-3">
              <div class="circle skeletonEffect p-mr-2"
                   size="4rem"
              ></div>
              <div class="stripes">
                <div class="p-mb-2 skeletonEffect"></div>
                <div class="p-mb-2 skeletonEffect"></div>
              </div>
            </div>
            <div class="stripes fullStripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
          <div class="custom-skeleton">
            <div class="p-d-flex p-mb-3">
              <div class="circle skeletonEffect p-mr-2"
                   size="4rem"
              ></div>
              <div class="stripes">
                <div class="p-mb-2 skeletonEffect"></div>
                <div class="p-mb-2 skeletonEffect"></div>
              </div>
            </div>
            <div class="stripes full2Stripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>
