import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalHelper } from './global.helper';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { ThemesService } from './themes/themes.service';
import { ESupportedThemes } from './themes/themes.types';
import { LanguageService } from './dynamic/language.service';
import { CommonHelper, COPY, goToExternalLink } from './services/common.helper';
import { emptyLinkedInScrappedData, ILinkedInScrappedData, IScrappedEducationData, IScrappedExperienceData } from './global.types';
import { ReCaptcha2Component } from 'ngx-captcha';

declare const chrome: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})

export class AppComponent implements OnInit {

  @ViewChild('captchaRef') captchaRef: ReCaptcha2Component;

  public siteKey: string = environment.SITE_KEY;

  currentDate: Date = new Date();

  loginForm: any = FormGroup;

  recaptcha: any;
  errMsg: string = '';
  previousTab: string = '';
  activeTab: string = 'contact';
  noProtocolLinkedInUrl: string = '';

  showBack: boolean = false;
  isChromeTab: boolean = false;
  showPassword: boolean = false;
  isLinkedInUrl: boolean = false;
  isLinkedCmpUrl: boolean = false;
  isCaptchaChecked: boolean = false;

  linkedInScrappedData: ILinkedInScrappedData = COPY(emptyLinkedInScrappedData);

  constructor(
    private router: Router,
    public ls: LanguageService,
    public globalHelper: GlobalHelper,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private themesService: ThemesService,
    public commonHelper: CommonHelper,
  ) {
    this.checkIfChromeTab();
    this.router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        window.location.reload();
      }
    });
  }

  ngOnInit() {
    this.initialiseLoginForm();
    this.getProspectDetails();
    if (this.authService.loginUserDetails) {
      this.commonHelper.getSettings('onload');
    }
    this.commonHelper.postMessageToParent(this.commonHelper.pluginSettings);
  }

  promiscuousMode() {
    if (this.linkedInScrappedData.urlType === 'other') {
      return;
    }
    this.authService.promiscuousMode(this.linkedInScrappedData)
      .then();
  }

  getProspectDetails() {
    window.addEventListener('message', async(event) => {
      if (event.data?.type === 'messageFromContentScript') {
        const {
          urlType,
          contact,
          company,
          linkedinHTML,
          tabUrl,
        } = event.data;
        switch (urlType) {
        case 'contact':
          this.linkedInScrappedData.contact = contact;
          break;
        case 'company':
          this.linkedInScrappedData.company = company;
          break;
        }
        Object.assign(this.linkedInScrappedData, {
          linkedinHTML,
          tabUrl,
          urlType,
        });
      }
      this.setNavigation()
        .then();
    });
  }

  async setNavigation() {
    const linkedInUrl: any = await this.globalHelper.getActiveTabLinkedinUrl(this.linkedInScrappedData.tabUrl);
    this.noProtocolLinkedInUrl = linkedInUrl.finalLinkedInUrl;
    this.isLinkedInUrl = linkedInUrl.isLinkedInUrl || !linkedInUrl.finalLinkedInUrl;
    this.isLinkedCmpUrl = this.linkedInScrappedData.tabUrl.includes('https://www.linkedin.com/company/');
    this.activeTab = this.isLinkedInUrl ? (this.isLinkedCmpUrl ? 'company' : 'contact') : 'company';
    this.promiscuousMode();
  }

  initialiseLoginForm() {
    this.loginForm = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      password: ['', [Validators.required]],
      remember: [false],
    });
  }

  searchProspects(clicked: string) {
    this.commonHelper.pluginSettings.clicked = clicked;
    this.commonHelper.postMessageToParent(this.commonHelper.pluginSettings);
  }

  checkIfChromeTab(): void {
    this.isChromeTab = !!(chrome && chrome.runtime && chrome.runtime.id);
  }

  onLoginSubmit() {
    this.errMsg = '';
    if (this.loginForm.invalid) {
      if (!this.loginForm.value.emailId || !this.loginForm.value.password) {
        this.errMsg = this.ls.data.toasts.pleaseEnterValidEmail;
        return;
      }
    }
    if (this.loginForm.valid) {
      const browser: string = this.globalHelper.getBrowserNameAndVersion().name;
      const email = this.loginForm.value.emailId;
      const password = this.loginForm.value.password;
      const remember: boolean = false;
      const requestData: any = {
        email,
        password,
        remember,
        browser,
      };

      this.authService.login(requestData, this.recaptcha)
        .then(() => {
          // get plugin settings
          this.commonHelper.getSettings('login');
          setTimeout(() => {
            this.loginForm.reset();
          }, 1000, this);
        })
        .catch((error: any) => {
          console.error(error);
          if (error.error.langCode) {
            this.errMsg = this.ls.takeErrorMsg(error.error.langCode);
          } else {
            this.errMsg = error || this.ls.data.toasts.somethingWentWrong;
          }
          setTimeout(() => {
            this.errMsg = '';
          }, 3000);
        })
        .finally(() => {
          this.recaptcha = undefined;
          this.isCaptchaChecked = false;
          if (this.isLinkedCmpUrl) {
            this.toggleFill('company', false);
          } else {
            this.toggleFill('contact', false);
          }
          this.captchaRef?.reloadCaptcha();
        });
    }
  }

  logout() {
    this.themesService.defineThemes('blue-theme' as ESupportedThemes);
    let browserDetails = this.globalHelper.getBrowserNameAndVersion();
    let browserName = browserDetails.name || 'other';
    let body = { browser: browserName };
    this.authService.logout(body)
      .subscribe({
        next: (_data: any) => {
          this.isCaptchaChecked = false;
        },
        error: (_error: any) => {
          console.error('_error', _error);
        },
      });
  }

  triggerParentBodyClick(): void {
    window.close();
  }

  toggleFill(item: string, isInternalLink: boolean) {
    this.showBack = false;
    this.previousTab = this.activeTab;
    this.activeTab = this.activeTab !== item ? this.activeTab = item : item;
    if (item === 'search') {
      goToExternalLink(`${environment.PRE_URL}/auth/login?loaded=1&returnUrl=search`, true);
      setTimeout(() => {
        if (!this.isLinkedCmpUrl) {
          this.toggleFill('contact', false);
        } else {
          this.toggleFill('company', false);
        }
      }, 500);
    }
    if (item && isInternalLink) {
      this.showBack = isInternalLink;
    }
  }

  returnBack() {
    this.toggleFill(this.previousTab, false);
  }

  onCaptchaPass(token: any) {
    this.recaptcha = token;
    this.isCaptchaChecked = true;
  }

  onCaptchaFail() {
    this.isCaptchaChecked = false;
    this.captchaRef?.reloadCaptcha();
  }

  minimizeExt(action: string) {
    this.commonHelper.pluginSettings.minimize = action;
    this.commonHelper.postMessageToParent(this.commonHelper.pluginSettings);
  }

  readonly environment: any = environment;

  protected readonly goToExternalLink = goToExternalLink;
}
