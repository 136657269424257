import { ESupportedThemes, IStyles } from './themes.types';
import * as Themes from './themes-list';

export class ThemesService {
  // default set theme
  data: IStyles = Themes.ThemeBlue;

  constructor() {
    this.setThemesVariables();
  }

  setThemesVariables() {
    document.documentElement.style.setProperty('--text-color', this.data.text);
    document.documentElement.style.setProperty('--primary-color', this.data.primary);
    document.documentElement.style.setProperty('--secondary-color', this.data.secondary);
    document.documentElement.style.setProperty('--background-color', this.data.background);
    document.documentElement.style.setProperty('--hover-color', this.data.hover);
    document.documentElement.style.setProperty('--white-background-color', this.data.whiteBackground);
    document.documentElement.style.setProperty('--shadow-color', this.data.shadow);
    document.documentElement.style.setProperty('--tagColor', this.data.tagColor);
    document.documentElement.style.setProperty('--green', this.data.green);
    document.documentElement.style.setProperty('--red', this.data.red);
    document.documentElement.style.setProperty('--borderColor', this.data.borderColor);
    document.documentElement.style.setProperty('--gradientBackground', this.data.gradientBackground);
    document.documentElement.style.setProperty('--primarySvg', this.data.primarySvg);
    document.documentElement.style.setProperty('--secondarySvg', this.data.secondarySvg);
  }

  public defineThemes(definedThemes: ESupportedThemes) {
    switch (definedThemes) {
    case ESupportedThemes.LIGHT: {
      this.data = Themes.ThemeLight;
      console.info(`${definedThemes} selected`);
      break;
    }
    case ESupportedThemes.BLUE: {
      this.data = Themes.ThemeBlue;
      console.info(`${definedThemes} selected`);
      break;
    }
    default: {
      console.error('Error while using ', definedThemes);
    }
    }

    this.setThemesVariables();
  }

}
