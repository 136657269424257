import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ContactComponent } from './components/contact/contact.component';
import { TokenInterceptor } from './auth/token.interceptor';
import { TooltipModule } from 'ng2-tooltip-directive';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { CompanyComponent } from './components/company/company.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { environment } from 'src/environments/environment';
import { SettingsComponent } from './components/settings/settings.component';
import { ActivityComponent } from './components/activity/activity.component';
import { ThemesService } from './themes/themes.service';
import { LanguageService } from './dynamic/language.service';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    CompanyComponent,
    EmployeeComponent,
    SettingsComponent,
    ActivityComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    HttpClientModule,
    TooltipModule,
    RouterModule,
    RouterModule.forRoot([]),
    NgbModule,
    NgSelectModule,
    NgxCaptchaModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    ThemesService,
    LanguageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
