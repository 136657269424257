import { Component, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CommonService } from 'src/app/services/common.service';
import { CommonHelper, goToExternalLink } from '../../services/common.helper';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/dynamic/language.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.less', '../contact/contact.component.less'],
})
export class EmployeeComponent implements OnInit {

  @ViewChild('listSelect') listSelect!: NgSelectComponent;

  openFilter: boolean = false;
  isFilterApplied: boolean = false;

  filtersDept: string[] = [];
  filtersSeniority: string[] = [];
  selectedFiltersDept: Array<string> = [];
  selectedFiltersSeniority: Array<string> = [];

  filterDept: any;
  contactData: any;
  filterSeniority: any;

  isCntListAdded: boolean[] = new Array().fill(false);

  pre_url: string = '';
  searchText: string = '';
  selectedFilterOption: string = '';

  timeout: number = 0;

  constructor(
    public commonService: CommonService,
    public commonHelper: CommonHelper,
    public ls: LanguageService,
  ) {
    this.pre_url = environment.PRE_URL || '';
  }

  ngOnInit(): void {
    this.initMethods();
  }

  initMethods() {
    this.commonHelper.getEmployeeList(0, 25, this.searchText, false)
      .then();
    this.getDepartmentSeniority();
    this.commonHelper.getDefaultCntList();
    this.commonHelper.getDefaultList();
  }

  getDepartmentSeniority() {
    this.commonService.getDepartmentSeniority()
      .then((data: any) => {
        if (data && data.seniority && data.department) {
          this.filtersSeniority = data.seniority.map((item: any) => item.name);
          this.filtersDept = data.department.map((item: any) => item.name);
        }
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  goToCntDetails(cntId: string) {
    if (cntId && this.commonHelper.pDetails.isSubExpire == '') {
      goToExternalLink(`${this.pre_url}auth/login?loaded=1&returnUrl=/search/contact/${cntId}`, true);
    } else {
      this.commonHelper.toastMessages(this.ls.data.errors.SIE, 'error');
    }
  }

  handleContactView(contactId: any, toggleCollapse: boolean = false) {
    const handleData = (contactData: any) => {
      // Store the current contact data
      const previousContactData = this.contactData;

      // Set the current contact data
      this.contactData = contactData;

      // Check if a previous contact was open and restore its data
      if (previousContactData && previousContactData.contactId !== contactId) {
        const previousContact = this.commonHelper.employeeData.cntData.find((contact: any) => contact.contactId === previousContactData.contactId);
        if (previousContact) {
          previousContact.isContactCollapse = true; // Expand the previous contact
          previousContact.alreadyView = true; // Mark the previous contact as already viewed
        }
      }

      // Update the current contact state
      this.commonHelper.employeeData.cntData.forEach((contact: any) => {
        contact.isContactCollapse = toggleCollapse ? contact.contactId === contactId ? !contact.isContactCollapse : false : contact.contactId === contactId;
        contact.alreadyView = contact.contactId === contactId ? true : contact.alreadyView;
      });
    };

    const handleError = (_error: any) => {
      this.commonHelper.toastMessages(this.ls.takeErrorMsg(_error?.error?.langCode), 'error');
    };

    this.commonService.contactView(contactId)
      .then(data => {
        handleData(data); // Call your success handler with the resolved data
      })
      .catch(error => {
        handleError(error); // Call your error handler with the caught error
      });
  }

  getCntList(contactId: any, i: any, companyId: any) {
    this.commonHelper.getCntList(contactId);
    if (this.commonHelper.defaultList.defaultSelectedCntList !== undefined) {
      if (this.commonHelper.pluginSettings.askWhereToSave) {
        this.isCntListAdded.fill(false);
        this.isCntListAdded[i] = true;
        this.commonHelper.selectedListType.selectedCntList = this.commonHelper.defaultList.defaultSelectedCntList ? [this.commonHelper.defaultList.defaultSelectedCntList] : [];
      } else {
        this.saveEmpContactList([this.commonHelper.defaultList.defaultSelectedCntList], i, contactId, companyId);
        this.commonHelper.getEmployeeList(0, 5)
          .then();
        if (this.listSelect) {
          this.listSelect.clearModel();
        }
      }
    } else {
      this.isCntListAdded.fill(false);
      this.isCntListAdded[i] = true;
      this.commonHelper.getCntList(contactId);
      if (this.listSelect) {
        this.listSelect.clearModel();
      }
    }
  }

  onListCancel(i: any) {
    this.isCntListAdded[i] = !this.isCntListAdded[i];
    if (this.listSelect) {
      this.listSelect.clearModel();
    }
  }

  goToProspect() {
    goToExternalLink(`${this.pre_url}/auth/login?&cmpName=${this.commonHelper.pDetails.company_name}`, true);
  }

  searchEmployee() {
    this.commonHelper.employeeData.cndDataLoader = true;
    this.commonHelper.getEmployeeList(0, 25, this.searchText, false)
      .then();
  }

  triggerSearch() {
    clearTimeout(this.timeout);
    this.timeout = Number(setTimeout(() => {
      this.searchEmployee();
    }, 1000));
  }

  openFilters() {
    this.openFilter = !this.openFilter;
  }

  selectedFilters(selected: string) {
    this.selectedFilterOption = selected;
    this.isFilterApplied = true;
    this.openFilter = false;
  }

  addFilters(filter: string) {
    if (this.selectedFilterOption === 'Department') {
      if (this.selectedFiltersDept.includes(filter)) {
        this.selectedFiltersDept = this.selectedFiltersDept.filter(item => item !== filter);
      } else {
        this.selectedFiltersDept.push(filter);
      }
    }
    if (this.selectedFilterOption === 'Seniority') {
      if (this.selectedFiltersSeniority.includes(filter)) {
        this.selectedFiltersSeniority = this.selectedFiltersSeniority.filter(item => item !== filter);
      } else {
        this.selectedFiltersSeniority.push(filter);
      }
    }
    this.filterDept = this.selectedFiltersDept;
    this.filterSeniority = this.selectedFiltersSeniority;
    this.commonHelper.getEmployeeList(0, 25, this.searchText, this.filterDept, this.filterSeniority)
      .then();

  }

  saveEmpContactList(saveCntList: any, i: any, contactId: any, companyId?: any) {

    if (this.commonHelper.defaultList.defaultSelectedCntList !== '') {
      this.isCntListAdded[i] = false;
    } else {
      this.isCntListAdded[i] = !this.isCntListAdded[i];
      if (this.listSelect) {
        this.listSelect.clearModel();
      }
    }

    if (saveCntList.length) {
      let request: any = {
        'indexIds': [contactId],
        'moduleType': 'mylist',
        'type': 'contact',
        'listName': saveCntList,
      };
      this.commonService.saveCntList(request)
        .then(() => {
          this.commonHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          this.onListCancel('contact');
          if (companyId) {
            this.commonHelper.getEmployeeList(0, 25, this.searchText, false)
              .then();
          }
        })
        .catch((_error: any) => {
          this.commonHelper.toastMessages(this.ls.data.contact.contactAlredyExist, 'error');
          this.onListCancel('contact');
        });
    }
    if (this.listSelect) {
      this.listSelect.clearModel();
    }
  }

  clearAll() {
    this.searchText = '';
    this.selectedFilterOption = '';
    this.isFilterApplied = false;
    this.filterDept = [];
    this.filterSeniority = [];
    this.selectedFiltersDept = [];
    this.selectedFiltersSeniority = [];
    this.commonHelper.getEmployeeList(0, 25, this.searchText, false)
      .then();
  }

  protected readonly goToExternalLink = goToExternalLink;
}
