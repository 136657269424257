<!--  chrome extension  -->
<ng-container *ngIf="isChromeTab">
  <div class="container px-lg-5 no-gutters">
    <div class="row">
      <div class="col-md-10">
        <!-- header -->
        <div class="row logo-t">
          <div class="col-6">
            <img src="assets/images/predictiv-logo.png"
                 alt="Predictiv"
                 loading="lazy"
                 class="pre-logo-w"
                 (click)="goToExternalLink(environment.PRE_URL,true)"
            >
          </div>
          <div class="col-6 mr-3 text-end">
            <div>
              <i class="fa fa-window-minimize d-inline-block sign-out"
                 aria-hidden="true"
                 [tooltip]="ls.data.global.buttons.close"
                 placement="bottom"
                 (click)="triggerParentBodyClick()"
              ></i>
            </div>
          </div>
        </div>
        <!-- body -->
        <div class="row g-0">
          <div class="col-lg-5 mb-lg-0">
            <div class="bg-white dash-wrapper">
              <div class="pl-2">
                <p class=" form-text way-to-work">
                  {{ ls.data.settings.platform }}
                </p>
                <div class="desc">
                  <div class="desc-left">
                    <img alt="Predictiv platform"
                         class="vts-svg-primary ico0"
                         src="assets/images/svg/settings/dashboard.svg"
                    >
                    <span class="ml-2-dashboard linkedin-prospector cursor-point">
                  <a (click)="goToExternalLink(environment.PRE_URL+'/dashboard',true)">
                    {{ ls.data.dashboard.goToPredictiv }}
                  </a>
                </span>
                  </div>
                  <div class="desc-right">
                    <img (click)="goToExternalLink(environment.PRE_URL+'/dashboard',true)"
                         alt="redirect"
                         class="vts-svg-primary redirect"
                         src="assets/images/xd-redirect.svg"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white dash-wrapper">
              <div class="pl-2">
                <p class=" form-text way-to-work">
                  {{ ls.data.dashboard.extension }}
                </p>
                <div class="desc">
                  <div class="desc-left">
                    <img src="assets/images/svg/linkedin.svg"
                         alt="linkedin"
                    >
                    <span class="ml-2-dashboard linkedin-prospector cursor-point">
                      <a (click)="goToExternalLink('https://www.linkedin.com/feed/',true)">
                        {{ ls.data.dashboard.linkedIn }}
                      </a>
                    </span>
                  </div>
                  <div class="desc-right">
                    <img (click)="goToExternalLink('https://www.linkedin.com/feed/',true)"
                         alt="redirect"
                         class="vts-svg-primary redirect"
                         src="assets/images/xd-redirect.svg"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- login page -->
<ng-container *ngIf="!isChromeTab && !authService.isLoggedIn">
  <section class="vts-gradient-background login-section">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center p-3">
          <img class="login-logo"
               src="assets/images/predictiv-logo.png"
               alt="Predictiv"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card bg-white card-corner">
            <div class="card-body p-3 text-center">
              <h5 class="text-center mb-3">
                {{ ls.data.dashboard.welcome }}
              </h5>
              <form [formGroup]="loginForm"
                    (ngSubmit)="onLoginSubmit()"
                    class="login_form"
              >
                <div class="did-floating-label-content form-outline mb-3">
                  <span>
                    <i class="fa fa-envelope"
                       aria-hidden="true"
                    ></i>
                  </span>
                  <input class="vts-inputText did-floating-input form-control"
                         type="email"
                         formControlName="emailId"
                  >
                  <label class="did-floating-label">
                    {{ ls.data.dashboard.email }}
                  </label>
                </div>
                <div class="did-floating-label-content form-outline mb-3">
                  <span>
                    <i class="fa fa-eye"
                       aria-hidden="true"
                       *ngIf="showPassword"
                       (click)="showPassword = !showPassword"
                    ></i>
                    <i class="fa fa-eye-slash"
                       aria-hidden="true"
                       *ngIf="!showPassword"
                       (click)="showPassword = !showPassword"
                    ></i>
                  </span>
                  <input class="vts-inputText did-floating-input form-control"
                         [type]='showPassword ? "text" : "password"'
                         formControlName="password"
                  >
                  <label class="did-floating-label">
                    {{ ls.data.dashboard.password }}
                  </label>
                </div>
                <div class="captcha"
                     *ngIf="!environment.QA"
                >
                  <ngx-recaptcha2 #captchaRef
                                  class="captcha"
                                  [siteKey]="siteKey"
                                  (error)="onCaptchaFail()"
                                  (expire)="onCaptchaFail()"
                                  (success)="onCaptchaPass($event)"
                  ></ngx-recaptcha2>
                  <div class="captcha-logo">
                    <img class="login-logo"
                         src="assets/images/captcha.png"
                         alt="hCaptcha-logo"
                    >
                  </div>
                  <div class="layerToHide"></div>
                  <div class="captchaBorder"></div>
                </div>
                <button type="submit"
                        class="vts-theme-button btn btn-primary btn-block mb-3"
                        [disabled]="!loginForm.valid || (environment.QA === false && !isCaptchaChecked)"
                >
                  {{ ls.data.dashboard.login }}
                  <i class="fa fa-arrow-right m-1"
                     aria-hidden="true"
                  ></i>
                </button>
              </form>
              <div class="alert alert-danger alert-danger-custom"
                   role="alert"
                   *ngIf="errMsg"
              >
                {{ errMsg }}
              </div>
              <p style="font-size: 12px;">
                {{ ls.data.footer.copyright }}
                {{ currentDate | date:"yyyy" }} |
                {{ ls.data.footer.privacyPolicy }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="minIco"
         (click)="minimizeExt('minimize')"
    >
      -
    </div>
  </section>
</ng-container>

<!-- non profile page -->
<ng-container *ngIf="!isChromeTab && linkedInScrappedData.urlType === 'other' && authService.isLoggedIn">
  <header>
    <section class="header">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="logoDiv">
              <img class="logo"
                   src="assets/images/predictiv-logo.png"
                   alt="Predictiv"
                   (click)="goToExternalLink(environment.PRE_URL,true)"
              >
            </div>
          </div>
          <div class="col-6">
            <div class="header-actions">
              <div class="header-action">
                <i class="vts-icon-color fa fa-minus"
                   aria-hidden="true"
                   (click)="minimizeExt('minimize')"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </header>
  <section class="vts-gradient-background non-profile login-section">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-3">
          <p class="descTop">
            {{ ls.data.dashboard.discoverLimitLess }}
          </p>
        </div>
        <div class="col-12 text-center mb-2">
          <img class="login-logo"
               src="assets/images/top.svg"
               alt="Predictiv"
               style="width: 100px;"
          >
        </div>
        <div class="col-12 mb-4">
          <div class="card bg-white card-corner">
            <div class="card-body p-1 ">
              <div class="card-top"
                   (click)="searchProspects('clicked')"
              >
                <div>
                  <h4>
                    {{ ls.data.dashboard.contactProspecting }}
                  </h4>
                  <p>
                    {{ ls.data.dashboard.exploreLinkedInProfile }}
                  </p>
                </div>
                <div>
                  <i class="fa fa-angle-right"
                     aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="card-bottom">
                <img class="login-logo"
                     src="assets/images/tmp.png"
                     alt="Predictiv"
                     style="width: 290px;margin-bottom: -45px;"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <div class="card bg-white card-corner">
            <div class="card-body p-1">
              <div class="card-top"
                   (click)="searchProspects('clicked')"
              >
                <div>
                  <h4>
                    {{ ls.data.dashboard.companyProspecting }}
                  </h4>
                  <p>
                    {{ ls.data.dashboard.exploreCompanyProfiles }}
                  </p>
                </div>
                <div>
                  <i class="fa fa-angle-right"
                     aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="card-bottom">
                <img class="login-logo"
                     src="assets/images/tmp2.png"
                     alt="Predictiv"
                     style="width: 290px;margin-bottom: -45px;"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<!-- side chrome extension -->
<ng-container
  *ngIf="!isChromeTab && authService.isLoggedIn && isLinkedInUrl && linkedInScrappedData.urlType !== 'other'">

  <!-- header -->
  <header>
    <section class="header">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="logoDiv">
              <img class="backIco"
                   src="assets/images/svg/back.svg"
                   alt="back"
                   *ngIf="showBack" (click)="returnBack()"
              >
              <img class="logo"
                   src="assets/images/predictiv-logo.png"
                   alt="Predictiv"
                   (click)="goToExternalLink(environment.PRE_URL,true)"
              >
            </div>
          </div>
          <div class="col-6">
            <div class="header-actions">
              <div class="header-action"
                   *ngIf="authService.loginUserDetails?.subTier !== 'ENTPR' && activeTab !== 'settings'"
              >
                <strong class="vts-icon-color"
                        (click)="goToExternalLink(environment.PRE_URL+'/auth/login?loaded=1&returnUrl=/settings/pricing',true)"
                >
                  {{ ls.data.global.misc.upgrade }}
                </strong>
              </div>
              <div class="header-action"
                   (click)="toggleFill('settings',false)"
              >
                <img class="vts-svg-primary"
                     [src]="activeTab === 'settings' ? 'assets/images/svg/settings-fill.svg' : 'assets/images/svg/settings.svg'"
                     alt="settings"
                />
              </div>
              <div class="header-action">
                <i class="vts-icon-color fa fa-minus"
                   aria-hidden="true"
                   (click)="minimizeExt('minimize')"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </header>

  <!-- toast messages-->
  <div *ngIf="commonHelper.toastMessage.visibility"
       class="toast-messages"
  >
    <div class="saved_list"
         [ngClass]="{'saved_list_error': commonHelper.toastMessage.type}"
    >
      <div class="saved_list_left"
           [ngClass]="{'toastMsg': commonHelper.toastMessage.type}"
      >
        <img [src]="commonHelper.toastMessage.type ? 'assets/images/error.png' : 'assets/images/circle.png'"
             alt="warn-icon"
        >&nbsp;
        <strong>
          {{ commonHelper.toastMessage.message }}&nbsp;&nbsp;
        </strong>
      </div>
      <div class="saved_list_right">
        <i class="fa fa-times"
           aria-hidden="true"
           (click)="commonHelper.closeNotify()"
        ></i>
      </div>
    </div>
  </div>

  <!-- contact page -->
  <section class="body"
           *ngIf="activeTab === 'contact' "
  >
    <div class="container p-0">
      <app-contact [isLinkedInUrl]="isLinkedInUrl"
                   [noProtocolLinkedInUrl]="noProtocolLinkedInUrl"
                   [linkedInScrappedData]="linkedInScrappedData"
      ></app-contact>
    </div>
  </section>

  <!-- company page -->
  <section class="body"
           *ngIf="activeTab === 'company'"
  >
    <div class="container p-0">
      <app-company [isLinkedInUrl]="isLinkedInUrl"
                   [noProtocolLinkedInUrl]="noProtocolLinkedInUrl"
                   [linkedInScrappedData]="linkedInScrappedData"
      ></app-company>
    </div>
  </section>

  <!-- employee page -->
  <section class="body"
           *ngIf="activeTab === 'employee'"
  >
    <div class="container p-0">
      <app-employee></app-employee>
    </div>
  </section>

  <!-- activity page -->
  <section class="body"
           *ngIf="activeTab === 'activity'"
  >
    <div class="container p-0">
      <app-activity></app-activity>
    </div>
  </section>

  <!-- settings page -->
  <section class="body"
           *ngIf="activeTab === 'settings'"
  >
    <div class="container p-0">
      <app-settings (logout)="logout()"
      ></app-settings>
    </div>
  </section>

  <!-- footer -->
  <footer>
    <nav>
      <div class="nav-item active"
           (click)="toggleFill('contact',false)"
           [ngClass]="{'active': activeTab === 'contact'}"
           *ngIf="!isLinkedCmpUrl"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'contact' ? 'assets/images/svg/contact-fill.svg' : 'assets/images/svg/contact.svg'"
               alt="contact"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.contact }}
          </strong>
        </div>
      </div>
      <div class="nav-item"
           (click)="toggleFill('company',false)"
           [ngClass]="{'active': activeTab === 'company'}"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'company' ?
                'assets/images/svg/company-fill.svg' : 'assets/images/svg/company.svg'"
               alt="company"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.company }}
          </strong>
        </div>
      </div>
      <div class="nav-item"
           (click)="toggleFill('employee',false)"
           [ngClass]="{'active': activeTab === 'employee'}"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'employee' ?
                'assets/images/svg/employee-fill.svg' : 'assets/images/svg/employee.svg'"
               alt="employee"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.employees }}
          </strong>
        </div>
      </div>
      <div class="nav-item"
           (click)="toggleFill('search',false)"
           [ngClass]="{'active': activeTab === 'search'}"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'search' ? 'assets/images/svg/search-fill.svg' : 'assets/images/svg/search.svg'"
               alt="search"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.search }}
          </strong>
        </div>
      </div>
      <div class="nav-item"
           (click)="toggleFill('activity',false)"
           [ngClass]="{'active': activeTab === 'activity'}"
      >
        <div class="nav-item-top">
          <img class="vts-svg-primary"
               [src]="activeTab === 'activity' ? 'assets/images/svg/list-fill.svg' : 'assets/images/svg/list.svg'"
               alt="activity"
          />
        </div>
        <div class="nav-item-bottom">
          <strong>
            {{ ls.data.dashboard.activity }}
          </strong>
        </div>
      </div>
    </nav>
  </footer>
</ng-container>
