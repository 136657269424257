export enum ISupportedLang {
  ENGLISH = 'English'
}

/**
 * @description
 * First level - placement (global or component like login, register
 * (it could be auth if you want to join login and register for an example) etc.)
 * @example
 * {
 *   global: {
 *     // next levels
 *   }
 *   auth: {
 *     // next levels
 *   }
 *   header: {
 *     // next levels
 *   }
 * }
 * @description
 * Second level - groups (buttons in global, form in registration etc.)
 * @example
 * {
 *   login: {
 *     form: {
 *       // next levels
 *     }
 *   }
 * }
 * @description
 * Next levels - actual data (apply for global.buttons etc.) or next groups if needed
 * @example
 * {
 *   login: {
 *     form: {
 *       email: {
 *         label: string;
 *         placeholder: string;
 *       }
 *       password: {
 *         label: string;
 *         placeholder: string;
 *       }
 *     }
 *   }
 * }
 */
export interface ILang {
  global: {
    misc: {
      copyright: string;
      predictiv: string;
      comingSoon: string;
      loading: string;
      notAvailable: string;
      viewLess: string;
      viewMore: string;
      upgrade: string;
    }
    buttons: {
      cancel: string;
      save: string;
      copy: string;
      copied: string;
      close: string;
      no: string;
      yes: string;
      edit: string;
    },
    toolTip: {
      editProfile: string;
    }
  }
  footer: {
    copyright: string;
    privacyPolicy: string;
  }
  company: {
    copiedSuccess: string;
    savedToList: string;
    unableToSave: string;
    fileNameAlredyExist: string;
    companyAlredyExist: string;
    subscribeIsExpire: string;
    noActiveSubPresent: string;
    yourViewCreditExeed: string;
    createNewList: string;
    moreCompanyDetails: string;
    noCompanyDataFoundYetWe: string;
    workEmail: string;
    phoneOffice: string;
    directPhone: string;
    showEmployees: string;
    keyEmployees: string;
    specialities: string;
    idustry: string;
    annualRevenueRange: string;
    employeeSize: string;
    headquatersLocation: string;
    foundIn: string;
    companyInfo: string;
    oneViewIsRequire: string;
    hqPrefix: string;
    placeholder: {
      searchCreateNewList: string;
    }
    toolTip: {
      addToList: string;
    }
  }
  contact: {
    stayTunedDataCommingSoon: string;
    contactAlredyExist: string;
    officeEmail: string;
    showContact: string;
    officePhone: string;
    moreContactDetails: string;
    about: string;
  }
  dashboard: {
    linkedInProspector: string;
    linkedIn: string;
    predictivExtension: string;
    extension: string;
    dashboard: string;
    predictivPlatform: string;
    goToPredictiv: string;
    wayToWorkWithPredictiv: string;
    activity: string;
    search: string;
    employees: string;
    company: string;
    contact: string;
    exploreCompanyProfiles: string;
    companyProspecting: string;
    exploreLinkedInProfile: string;
    contactProspecting: string;
    discoverLimitLess: string;
    login: string;
    password: string
    email: string
    welcome: string
  }
  employee: {
    noDataFound: string;
    viewMore: string;
    clearAll: string;
    department: string;
    seniority: string;
    startTypingTosearch: string;
  }
  activities: {
    activity: string;
    recent: string;
    company: string;
    contact: string;
  }
  settings: {
    areYouSureYouWantSign: string;
    signOut: string;
    askWhereToSaveList: string;
    defaultList: string;
    predictivEveryWhere: string;
    autoOpen: string;
    pluginPosition: string;
    extensionPreferences: string;
    predictivPlatform: string;
    platform: string;
    credits: string;
    themes: string;
  }
  toasts: {
    somethingWentWrong: string;
    pleaseEnterValidEmail: string;
    unableToComplete: string;
  },
  errors: {
    placeholder: string;
    AUTH008: string;
    AUTH007: string;
    AUTH21: string;
    AUTH009: string;
    SIE: string;
    NOACTIVESUBPRESENT: string;
    YOURVIEWLIMITEXEED: string;
    YSHECSRI: string;
    YOUR001: string;
    YOUR0022222: string;
  },
  success: {
    placeholder: string;
  }
}
