export enum ESupportedThemes {
  LIGHT = 'light-theme',
  BLUE = 'blue-theme'
}

export interface IStyles {
  primary: string;
  secondary: string;
  text: string;
  background: string;
  hover: string;
  whiteBackground: string;
  shadow: string;
  tagColor: string;
  green: string;
  red: string;
  borderColor: string;
  gradientBackground: string;
  primarySvg: string; // this for blue SVGs only!
  secondarySvg: string; // this for grey SVGs only!
}
