import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from '../../../environments/environment';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AppComponent } from 'src/app/app.component';
import { CommonHelper, goToExternalLink } from '../../services/common.helper';
import { LanguageService } from 'src/app/dynamic/language.service';
import { AuthService } from 'src/app/services/auth.service';
import { IContactProfile, ILinkedInPayload, ILinkedInScrappedData } from '../../global.types';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
})
export class ContactComponent implements OnInit {

  @Input() noProtocolLinkedInUrl: string = '';
  @Input() isLinkedInUrl: boolean = false;
  @Input('linkedInScrappedData') linkedInScrappedData: ILinkedInScrappedData;
  @ViewChild('cntListSelect') cntListSelect!: NgSelectComponent;
  @ViewChild('cmpListSelect') cmpListSelect!: NgSelectComponent;

  isLoaded: boolean = false;
  isContactAvailable: boolean = false;
  isContactView: boolean = false;
  isCntListAdded: boolean = false;
  isCmpListAdded: boolean = false;
  isFaCheckFaCopyEM: boolean = false;
  isFaCheckFaCopyPO: boolean = false;
  isFaCheckFaCopyShowEM: boolean = false;
  isFaCheckFaCopyShowPD: boolean = false;
  isFaCheckFaCopyShowPO: boolean = false;

  copySucMsg: string = '';

  constructor(
    public commonService: CommonService,
    public commonHelper: CommonHelper,
    private appComponent: AppComponent,
    public authService: AuthService,
    public ls: LanguageService,
  ) {
  }

  ngOnInit(): void {
    if (!this.authService.loginUserDetails) {
      return;
    }
    this.getLinkedInProfileData(false, 'onLoad')
      .then();
    this.initMethods();
  }

  initMethods() {
    this.commonHelper.getDefaultCntList();
    this.commonHelper.getDefaultCmpList();
    this.commonHelper.getDefaultList();
  }

  redirectToEmp(item: string) {
    this.appComponent.toggleFill(item, true);
  }

  async getLinkedInProfileData(isView: boolean, type: string) {
    this.isLoaded = true;
    if (!this.noProtocolLinkedInUrl?.includes('linkedin.com/in/')) {
      this.isLoaded = false;
      return;
    }

    const request: ILinkedInPayload = {
      linkedIn: this.noProtocolLinkedInUrl,
      isView,
      fullName: this.linkedInScrappedData.contact.contactName,
      homeLocation: this.linkedInScrappedData.contact.experience[0].workLocation,
      companyName: this.linkedInScrappedData.contact.experience[0].companyName,
      jobTitle: this.linkedInScrappedData.contact.experience[0].jobTitle,
      workLocation: this.linkedInScrappedData.contact.experience[0].workLocation,
      workDuration: this.linkedInScrappedData.contact.experience[0].workDuration,
      experience: this.linkedInScrappedData.contact.experience,
      cmpLiUrl: this.linkedInScrappedData.contact.experience[0].companyLiUrl,
      cmpLogoUrl: this.linkedInScrappedData.contact.experience[0].companyLogoUrl,
      contactLogo: this.linkedInScrappedData.contact.contactImageUrl,
      contactLiUrl: this.linkedInScrappedData.tabUrl,
      linkedInHTML: this.linkedInScrappedData.linkedinHTML,
      isCmpUrl: false,
      isContact: true,
      chromeExtCredits: true,
    };

    try {

      const profileData: IContactProfile = await this.commonService.getContactProfile(request);

      if (!profileData) {
        const profileRegex = /^linkedin\.com\/in\/[a-z0-9-]+\/?$/;
        const recentActivityRegex = /^linkedin\.com\/in\/[a-z0-9-]+\/recent-activity\/all\/?$/;
        const isInvalidProfile = recentActivityRegex.test(this.noProtocolLinkedInUrl) || !profileRegex.test(this.noProtocolLinkedInUrl);
        return;
      }

      this.linkedInScrappedData.company.companyName = profileData.companyName;
      this.linkedInScrappedData.company.companyLiUrl = profileData.companyLiUrl;

      this.isContactAvailable = true;
      let imageUrl: string = '';
      let avatarStyle: {} = {};
      let companyLogo: any;

      if (profileData.domainId) {
        companyLogo = await this.commonHelper.getCompanyLogos([profileData.domainId]);
      }
      if (profileData.contactLogoId) {
        imageUrl = await this.commonHelper.getContactLogoUrl(profileData.contactLogoId);
        if (!imageUrl) {
          const avatarColorPair = this.commonHelper.preDefAvatarColors[
            Math.floor(Math.random() * this.commonHelper.preDefAvatarColors.length)
            ];
          avatarStyle = {
            'background-color': avatarColorPair.background,
            'color': avatarColorPair.text,
          };
        }
      }
      if (type === 'onLoad') {
        const linkedInExperience = this.linkedInScrappedData?.contact?.experience[0];
        const defaultCompanyName = linkedInExperience?.companyName || profileData.companyName;
        const defaultJobTitle = linkedInExperience?.jobTitle || profileData.jobTitle;
        this.commonHelper.pDetails = {
          ...this.commonHelper.pDetails,
          fullName: `${profileData.firstName} ${profileData.lastName}`,
          contactLiUrl: profileData.contactLiUrl || '',
          companyId: profileData.companyId || '',
          work_email: profileData.emailAddress || 'Not Available',
          phone_office: profileData.phoneOffice || 'Not Available',
          phone_direct: profileData.phoneDirect || 'Not Available',
          id: profileData.id || '',
          contactId: profileData.contactId || '',
          imageUrl,
          avatarStyle,
          cmpLogo: companyLogo?.[0]?.logo
            ? `${environment.CPM_LOGO_URL}${companyLogo[0].logo}`
            : linkedInExperience?.companyLogoUrl || '',
          parentDept: profileData.parentDept || 'Not Available',
          emp_range: profileData.empRange || 'Not Available',
          rev_range: profileData.revRange || 'Not Available',
          hq_location: profileData.hqLocation || 'Not Available',
          company_li_url: profileData.companyLiUrl || '',
          company_facebook_url: profileData.companyFacebookUrl || '',
          isSubExpire: profileData.isSubExpire,
          website: profileData.website || 'Not Available',
          specialty: Array.from(new Set(profileData.specialty)) || 'Not Available',
          founded: profileData.founded || 'Not Available',
          company_description: profileData.companyDescription || '',
          company_name: profileData.companyName === linkedInExperience?.companyName ? profileData.companyName : defaultCompanyName,
          job_title: profileData.jobTitle === linkedInExperience?.jobTitle ? profileData.jobTitle : defaultJobTitle,
        };
        if (this.commonHelper.pDetails.contactId) {
          this.commonHelper.getCntList(this.commonHelper.pDetails.contactId);
          this.commonHelper.getContactListSave(this.commonHelper.pDetails.contactId);
        }
        if (this.commonHelper.pDetails.companyId) {
          this.commonHelper.getCmpList(this.commonHelper.pDetails.companyId);
          this.commonHelper.getCompanyListSave(this.commonHelper.pDetails.companyId);
        }
        this.isContactView = isView || profileData.alreadyViewCnt || false;
      } else if (['office_email', 'office_phone', 'direct_phone'].includes(type)) {
        this.commonHelper.pDetails.work_email = profileData.emailAddress || 'Not Available';
        this.commonHelper.pDetails.phone_office = profileData.phoneOffice || 'Not Available';
        this.commonHelper.pDetails.phone_direct = profileData.phoneDirect || 'Not Available';
        this.isContactView = isView || profileData.alreadyViewCnt || false;
      }

      this.isFaCheckFaCopyShowEM = !!profileData.emailAddress;
      this.isFaCheckFaCopyShowPD = !!profileData.phoneDirect;
      this.isFaCheckFaCopyShowPO = !!profileData.phoneOffice;
      this.isFaCheckFaCopyEM = !(profileData.emailAddress && profileData.emailAddress.includes('*'));
      this.isFaCheckFaCopyPO = !(profileData.phoneOffice && profileData.phoneOffice.includes('*'));

    } catch (error: any) {
      this.commonHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
    } finally {
      this.isLoaded = false;
    }
  }

  viewOfficeEmail(type: string) {
    if (this.commonHelper.pDetails.work_email === 'Not Available' &&
      this.commonHelper.pDetails.phone_office === 'Not Available' &&
      this.commonHelper.pDetails.phone_direct === 'Not Available') {
      this.isContactView = true;
    } else {
      this.getLinkedInProfileData(true, type)
        .then();
    }
  }

  goToCmpDetails(cmpId: string) {
    if (!this.commonHelper.pDetails.isSubExpire) {
      goToExternalLink(`${environment.PRE_URL}auth/login?loaded=1&returnUrl=/search/company/${cmpId}`, true);
    }
  }

  onListCancel(type: 'contact' | 'company') {
    const listSelect: NgSelectComponent = type === 'contact' ? this.cntListSelect : this.cmpListSelect;
    if (listSelect) {
      listSelect.clearModel();
    }
    this.isCntListAdded = type === 'contact' ? false : this.isCntListAdded;
    this.isCmpListAdded = type === 'company' ? false : this.isCmpListAdded;
  }

  saveContactList(saveCntList: string[]) {
    if (saveCntList.length) {
      const body = {
        'indexIds': [this.commonHelper.pDetails.contactId],
        'moduleType': 'mylist',
        'type': 'contact',
        'listName': saveCntList,
      };
      this.commonService.saveCntList(body)
        .then(() => {
          this.commonHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          this.onListCancel('contact');
          this.isCntListAdded = false;
          this.commonHelper.getContactListSave(this.commonHelper.pDetails.contactId);
        })
        .catch((_error: any) => {
          this.commonHelper.toastMessages(this.ls.data.contact.contactAlredyExist, 'error');
          this.onListCancel('contact');
          this.isCntListAdded = false;
        });
    }
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  saveCompanyList(saveCmpList: string[]) {
    if (saveCmpList.length) {
      const body = {
        'indexIds': [this.commonHelper.pDetails.companyId],
        'moduleType': 'mylist',
        'type': 'account',
        'listName': saveCmpList,
      };
      this.commonService.saveCmpList(body)
        .then(() => {
          this.commonHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          this.onListCancel('company');
          this.isCmpListAdded = false;
          this.commonHelper.getCompanyListSave(this.commonHelper.pDetails.companyId);
        })
        .catch((_error: any) => {
          this.commonHelper.toastMessages(this.ls.data.company.companyAlredyExist, 'error');
          this.onListCancel('company');
          this.isCmpListAdded = false;
        });
    }
    if (this.cmpListSelect) {
      this.cmpListSelect.clearModel();
    }
  }

  copyToClipboard(el: HTMLSpanElement, type: string) {
    this.copySucMsg = '';
    this.customCopyToClipboard(el.innerText);
    this.copySucMsg = type + ' Copied';
  }

  customCopyToClipboard(text: string) {
    const textarea: HTMLTextAreaElement = document.createElement('textarea');
    textarea.value = text;

    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';

    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand('copy');

    document.body.removeChild(textarea);

    this.commonHelper.toastMessages(this.ls.data.global.buttons.copied, 'success');
  }

  goToCntDetails(cntId: string) {
    if (!cntId) {
      return;
    }
    if (this.commonHelper.pDetails.isSubExpire === '') {
      goToExternalLink(`${environment.PRE_URL}auth/login?loaded=1&returnUrl=/search/contact/${cntId}`, true);
    } else {
      this.commonHelper.toastMessages(this.ls.data.errors.SIE, 'error');
    }
  }

  addToList() {
    this.commonHelper.getCmpList(this.commonHelper.pDetails.companyId);

    const defaultList = this.commonHelper.defaultList.defaultSelectedCmpList;

    if (defaultList !== undefined) {
      if (this.commonHelper.pluginSettings.askWhereToSave) {
        this.isCmpListAdded = !this.isCmpListAdded;
        this.commonHelper.selectedListType.selectedCmpList = [defaultList];
      } else {
        this.isCmpListAdded = false;
        this.saveCompanyList([defaultList]);
      }
    } else {
      this.isCmpListAdded = !this.isCmpListAdded;
    }

    this.cmpListSelect?.clearModel();
  }

  getCntList() {
    this.commonHelper.getCntList(this.commonHelper.pDetails.contactId);

    const defaultCntList = this.commonHelper.defaultList.defaultSelectedCntList;

    if (defaultCntList !== undefined) {
      if (this.commonHelper.pluginSettings.askWhereToSave) {
        this.isCntListAdded = !this.isCntListAdded;
        this.commonHelper.selectedListType.selectedCntList = [defaultCntList];
      } else {
        this.isCntListAdded = false;
        this.saveContactList([defaultCntList]);
      }
    } else {
      this.isCntListAdded = !this.isCntListAdded;
    }

    this.cntListSelect?.clearModel();
  }

  protected readonly goToExternalLink = goToExternalLink;
}
