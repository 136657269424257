<div class="main-body">
  <!-- employees filter top -->
  <div class="custom-card">
    <div class="profile-card">
      <div class="emp-top">
        <div class="card-title">
          <div class="vts-tag-color panel-title">
            {{ ls.data.dashboard.employees }}
          </div>
        </div>
      </div>
      <div class="emp-bottom">
        <div class="autoComplete">
          <div class="searchIco">
            <img class="vts-svg-primary"
                 src="assets/images/svg/search-icon.svg"
                 alt="search"
            >
          </div>
          <div class="vts-border-theme inputText">
            <input type="text"
                   name="search"
                   id="search"
                   [placeholder]="ls.data.employee.startTypingTosearch"
                   class="vts-inputText form-control"
                   [(ngModel)]="searchText"
                   (input)="triggerSearch()"
            >
          </div>
          <div class="SearchBtn">
            <button type="submit"
                    (click)="searchEmployee()"
                    class="vts-theme-button btn btn-primary btn-sm"
                    [disabled]="!searchText"
            >
              {{ ls.data.dashboard.search }}
            </button>
          </div>
        </div>
        <div class="filter-box">
          <div class="vts-border-theme filter"
               [ngClass]="{'vts-theme-button filtered': isFilterApplied}"
               (click)="openFilters()"
          >
            <img class="vts-svg-primary"
                 [ngClass]="{'vts-svg-primary': !isFilterApplied}"
                 [src]="!isFilterApplied ? 'assets/images/svg/filter-icon.svg': 'assets/images/svg/white-filter-icon.svg'"
                 alt="filter"
            >
            <span>
              {{ selectedFilterOption ? selectedFilterOption : 'Filter' }}
            </span>
          </div>
          <div class="filterOptions" *ngIf="openFilter">
            <ul class="vts-border-theme">
              <li (click)="selectedFilters('Seniority')">
                {{ ls.data.employee.seniority }}
              </li>
              <li class="d-none" (click)="selectedFilters('Department')">
                {{ ls.data.employee.department }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="appliedFilter" *ngIf="isFilterApplied">
        <div class="filterResultTop">
          <div class="filterResult">
            Applied Filter
            <span class="vts-theme-bg counter">
              {{ selectedFiltersSeniority.length + selectedFiltersDept.length }}
            </span>
          </div>
          <div class="filterResult clearAll"
               *ngIf="(selectedFiltersSeniority.length + selectedFiltersDept.length) !== 0"
               (click)="clearAll()"
          >
            {{ ls.data.employee.clearAll }}
          </div>
        </div>
        <div class="filterResultBottom">
          <ul *ngIf="filtersSeniority.length && selectedFilterOption === 'Seniority'">
            <li #filterEle1
                *ngFor="let filter of filtersSeniority"
                (click)="addFilters(filter)"
                [ngClass]="{'vts-text-box selected': selectedFiltersSeniority.includes(filter)}"
                [tooltip]="filterEle1.offsetWidth < filterEle1.scrollWidth ? filter :''"
                placement="bottom"
            >
              {{ filter }}
            </li>
          </ul>
          <ul *ngIf="filtersSeniority.length && selectedFilterOption === 'Department'">
            <li #filterEle2
                *ngFor="let filter of filtersDept"
                (click)="addFilters(filter)"
                [ngClass]="{'vts-text-box selected': selectedFiltersDept.includes(filter)}"
                [tooltip]="filterEle2.offsetWidth < filterEle2.scrollWidth ? filter :''"
                placement="bottom"
            >
              {{ filter }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--  employees bottom -->
  <ng-container
    *ngIf="!commonHelper.employeeData.cndDataLoader && commonHelper.employeeData.cntData && commonHelper.employeeData.cntData.length">
    <div class="custom-card mb-0">
      <div class="profile-card">
        <div class="empProfile-bottom">
          <div class="card-title">
            <div class="vts-tag-color panel-title">
              {{ commonHelper.employeeData.cntData.length }}
              {{ commonHelper.employeeData.cntData.length > 1 ? 'Employees' : 'Employee' }}
            </div>
            <div class="vts-icon-color link"
                 (click)="goToProspect()"
            >
              {{ ls.data.employee.viewMore }}
              <img class="vts-svg-primary"
                   src="assets/images/svg/redirect.svg"
                   alt="redirect"
              >
            </div>
          </div>
          <div class="viewEmpDetails">
            <div class="emp-details-list">
              <div *ngFor="let employee of commonHelper.employeeData.cntData;let i = index"
                   class="emp-list-item"
              >
                <div class="emp-list-top">
                  <div class="emp-list-top-left">
                    <div class="employee-logo">
                      <ng-container *ngIf="employee.imageUrl; else initials">
                        <img [src]="employee.imageUrl"
                             alt="contact-img"
                        >
                      </ng-container>
                      <ng-template #initials>
                        <div class="initials" [ngStyle]="employee.avatarStyle">
                          {{ commonHelper.getInitials(employee.fullName) }}
                        </div>
                      </ng-template>
                    </div>
                    <div class="employee-info">
                      <div class="employee-title">
                        <h4 #fullNameEle
                            class="vts-icon-color overflow-text"
                            [tooltip]="fullNameEle.offsetWidth < fullNameEle.scrollWidth ? employee.fullName :''"
                            placement="bottom"
                            (click)="goToCntDetails(employee.contactId)"
                        >
                          {{ employee.fullName }}
                        </h4>
                        <ng-container *ngIf="employee?.contact_li_url">
                          <img class="social_icon"
                               src="assets/images/svg/linkedin.svg"
                               alt="linkedin"
                               (click)="goToExternalLink(employee.contact_li_url,false)"
                          >
                        </ng-container>
                        <ng-container *ngIf="employee?.facebook_url">
                          <img class="social_icon"
                               src="assets/images/svg/facebook.svg"
                               alt="facebook"
                               (click)="goToExternalLink(employee.facebook_url,false)"
                          >
                        </ng-container>
                      </div>
                      <p #jobTitleEle
                         class="overflow-text"
                         [tooltip]="jobTitleEle.offsetWidth < jobTitleEle.scrollWidth ? employee.jobTitle :''"
                         placement="bottom"
                      >
                        {{ employee.jobTitle ? employee.jobTitle : "" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="emp-list-bottom">
                  <div class="emp-list-bottom-left">
                    <div class="vts-green-color-border empDetails">
                      <ul [ngClass]="{'viewed': employee.alreadyView}">
                        <li>
                          {{ ls.data.company.workEmail }}
                        </li>
                        <li>
                          {{ ls.data.company.phoneOffice }}
                        </li>
                        <li>
                          {{ ls.data.company.directPhone }}
                        </li>
                      </ul>
                      <span class="lock">
                        <i *ngIf="!employee.alreadyView"
                           class="vts-theme-bg fa fa-lock"
                           [ngClass]="{'disabled':!employee.revealContact}"
                           aria-hidden="true"
                           (click)="handleContactView(employee.contactId)"
                        ></i>
                        <i *ngIf="employee.alreadyView"
                           [ngClass]="{'vts-green-color-bg viewed viewed':employee.alreadyView}"
                           class="fa fa-unlock-alt"
                           aria-hidden="true"
                        ></i>
                      </span>
                      <span class="isLock"
                            *ngIf="employee.alreadyView"
                      >
                        <i *ngIf="employee.isContactCollapse"
                           [ngClass]="{'vts-red-color-bg viewed min':employee.alreadyView}"
                           (click)="handleContactView(employee.contactId,employee.isContactCollapse)"
                           class="fa fa-minus"
                           aria-hidden="true"
                        ></i>
                        <i *ngIf="!employee.isContactCollapse"
                           [ngClass]="{'vts-green-color-bg viewed viewed max':employee.alreadyView}"
                           (click)="handleContactView(employee.contactId,employee.isContactCollapse)"
                           class="fa fa-angle-down"
                           aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    <div class="empDetailsView"
                         *ngIf="employee.isContactCollapse && contactData"
                    >
                      <ul>
                        <li>
                          <div class="label">
                            {{ ls.data.company.workEmail }}
                          </div>
                          <div #emailEle
                               class="value"
                               [tooltip]="emailEle.offsetWidth < emailEle.scrollWidth ? contactData.emailAddress :''"
                               placement="top"
                          >
                            :
                            {{
                              contactData.emailAddress ? contactData.emailAddress : "Not Available"
                            }}
                          </div>
                        </li>
                        <li>
                          <div class="label">
                            {{ ls.data.company.phoneOffice }}
                          </div>
                          <div class="value">
                            :
                            {{ contactData.phoneOffice ? contactData.phoneOffice : "Not Available" }}
                          </div>
                        </li>
                        <li>
                          <div class="label">
                            {{ ls.data.company.directPhone }}
                          </div>
                          <div class="value">
                            :
                            {{ contactData.phoneDirect ? contactData.phoneDirect : "Not Available" }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="emp-list-bottom-right">
                    <div class="action">
                      <div class="addList">
                        <img class="vts-svg-primary cursor"
                             [src]="!employee.alreadySaved ? 'assets/images/svg/addList.svg' : 'assets/images/svg/addList-fill.svg'"
                             alt="addList"
                             (click)="getCntList(employee.contactId,i,employee.companyId)"
                             [tooltip]="ls.data.company.toolTip.addToList"
                             placement="top"
                        >
                        <div class="vts-border-theme save-contact"
                             *ngIf="isCntListAdded[i]"
                        >
                          <div class="form-group">
                            <ng-select #listSelect
                                       [items]="commonHelper.cntListData.cntListDataAvl"
                                       bindLabel="value"
                                       bindValue="value"
                                       [addTag]="commonHelper.addList"
                                       [addTagText]="ls.data.company.createNewList"
                                       [multiple]="true" [placeholder]="ls.data.company.placeholder.searchCreateNewList"
                                       [(ngModel)]="commonHelper.selectedListType.selectedCntList"
                                       (change)="commonHelper.onListChange('contact')"
                            ></ng-select>
                          </div>
                          <div class="actionButtons">
                            <span class="vts-theme-bg vts-border-theme fill-btn"
                                  (click)="saveEmpContactList(commonHelper.selectedListType.selectedCntList,i,employee.contactId,employee.companyId)"
                            >
                              {{ ls.data.global.buttons.save }}
                            </span>
                            <span class="vts-border-theme vts-icon-color"
                                  (click)="onListCancel(i)"
                            >
                              {{ ls.data.global.buttons.cancel }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- no data found -->
  <ng-container
    *ngIf="!commonHelper.employeeData.cndDataLoader && commonHelper.employeeData.cntData && !commonHelper.employeeData.cntData.length">
    <div class="main-body settings">
      <div class="custom-card">
        <h1 class="coming-soon">
          {{ ls.data.employee.noDataFound }}
        </h1>
      </div>
    </div>
  </ng-container>
  <!-- while everywhere -->
  <ng-container *ngIf="!commonHelper.employeeData.cntData && !commonHelper.employeeData.cndDataLoader">
    <div class="custom-card mb-0 customHeight customHeightEvr">
      <div class="profile-card">
        <div class="cmpProfile-bottom">
          <div class="vts-border-theme notFound text-center">
            <img class="vts-svg-primary"
                 src="assets/images/not-found.svg"
                 alt="not-found"
            >
            <p>
              {{ ls.data.company.noCompanyDataFoundYetWe }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- skeleton -->
  <ng-container *ngIf="commonHelper.employeeData.cndDataLoader">
    <div class="container p-0">
      <div class="row">
        <div class="skeleton">
          <div class="custom-skeleton">
            <div class="stripes fullEmpStripes">
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
              <br>
              <div class="p-mb-2 skeletonEffect"></div>
              <div class="p-mb-2 skeletonEffect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
