import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from '../../../environments/environment';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AppComponent } from 'src/app/app.component';
import { CommonHelper, goToExternalLink } from '../../services/common.helper';
import { LanguageService } from 'src/app/dynamic/language.service';
import { ICompanyProfile, ILinkedInPayload, ILinkedInScrappedData } from '../../global.types';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.less', '../contact/contact.component.less'],
})
export class CompanyComponent implements OnInit {

  @Input() noProtocolLinkedInUrl: string = '';
  @Input() isLinkedInUrl: boolean = false;
  @Input('linkedInScrappedData') linkedInScrappedData: ILinkedInScrappedData;
  @ViewChild('cntListSelect') cntListSelect!: NgSelectComponent;
  @ViewChild('cmpListSelect') cmpListSelect!: NgSelectComponent;

  isLoaded: boolean = false;
  isCompanyAvailable: boolean = false;

  contactData: any;

  isCmpListAdded: boolean = false;
  isCntListAdded: boolean[] = new Array(this.commonHelper.employeeData.cntData.length).fill(false);

  constructor(
    public commonService: CommonService,
    public commonHelper: CommonHelper,
    private appComponent: AppComponent,
    public ls: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.getLinkedInProfileData();
    this.initMethods();
  }

  initMethods() {
    this.commonHelper.getDefaultCntList();
    this.commonHelper.getDefaultCmpList();
    this.commonHelper.getDefaultList();
  }

  redirectToEmp(item: string) {
    this.appComponent.toggleFill(item, true);
  }

  goToCntDetails(cntId: string) {
    if (!cntId) {
      return;
    }
    if (this.commonHelper.pDetails.isSubExpire === '') {
      goToExternalLink(`${environment.PRE_URL}auth/login?loaded=1&returnUrl=/search/contact/${cntId}`, true);
    } else {
      this.commonHelper.toastMessages(this.ls.data.errors.SIE, 'error');
    }
  }

  goToCompDetails(companyId: string) {
    if (!companyId) {
      return;
    }
    if (this.commonHelper.pDetails.isSubExpire == '') {
      goToExternalLink(`${environment.PRE_URL}auth/login?loaded=1&returnUrl=/search/company/${companyId}`, true);
    } else {
      this.commonHelper.toastMessages(this.ls.data.errors.SIE, 'error');
    }
  }

  getLinkedInProfileData() {
    if (this.noProtocolLinkedInUrl) {
      this.isLoaded = true;
      const body: ILinkedInPayload = {
        linkedIn: this.noProtocolLinkedInUrl,
        isView: false,
        fullName: '',
        homeLocation: '',
        companyName: this.linkedInScrappedData.company.companyName,
        jobTitle: '',
        workLocation: '',
        workDuration: '',
        experience: [],
        cmpLiUrl: this.linkedInScrappedData.company.companyLiUrl,
        cmpLogoUrl: this.linkedInScrappedData.company.companyLogoUrl,
        contactLogo: '',
        contactLiUrl: this.linkedInScrappedData.tabUrl,
        linkedInHTML: this.linkedInScrappedData.linkedinHTML,
        isCmpUrl: true,
        isContact: false,
        chromeExtCredits: true,
      };
      this.commonService.getCompanyProfile(body)
        .then(async(profileData: ICompanyProfile) => {
          if (!profileData) {
            return;
          }
          this.isCompanyAvailable = true;
          let companyLogoUrl: string = '';
          if (profileData.domainId) {
            const companyLogos: any = await this.commonHelper.getCompanyLogos([profileData.domainId]);
            companyLogoUrl = companyLogos.length && companyLogos[0]?.logo
              ? `${environment.CPM_LOGO_URL}${companyLogos[0].logo}`
              : this.linkedInScrappedData?.company?.companyLogoUrl || '';
          }
          const linkedInCompanyName: string = this.linkedInScrappedData?.company?.companyName;
          this.commonHelper.pDetails = {
            ...this.commonHelper.pDetails,
            company_name: profileData.companyName && linkedInCompanyName
              ? (profileData.companyName === linkedInCompanyName ? profileData.companyName : linkedInCompanyName)
              : profileData.companyName,
            companyId: profileData.companyId || '',
            parentDept: profileData.parentDept || 'Not Available',
            emp_range: profileData.empRange || 'Not Available',
            rev_range: profileData.revRange || 'Not Available',
            hq_location: profileData.hqLocation || 'Not Available',
            company_li_url: profileData.companyLiUrl || '',
            company_facebook_url: profileData.companyFacebookUrl || '',
            cmpLogo: companyLogoUrl,
            isSubExpire: profileData.isSubExpire || '',
            website: profileData.website || 'Not Available',
            specialty: Array.from(new Set(profileData.specialty)) || 'Not Available',
            founded: profileData.founded || 'Not Available',
            company_description: profileData.companyDescription || '',
          };
          if (this.commonHelper.pDetails.companyId) {
            this.commonHelper.getCmpList(this.commonHelper.pDetails.companyId);
            this.commonHelper.getCompanyListSave(this.commonHelper.pDetails.companyId);
            await this.commonHelper.getEmployeeList(0, 5);
          }

        })
        .catch((error: any) => {
          this.commonHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
        })
        .finally(() => {
          this.isLoaded = false;
        });
    }
  }

  onListCancel(type: 'contact' | 'company', i?: any) {
    this.isCntListAdded[i] = !this.isCntListAdded[i];
    if (type === 'contact' && this.cntListSelect) {
      this.cntListSelect.clearModel();
    } else if (type === 'company' && this.cmpListSelect) {
      this.cmpListSelect.clearModel();
      this.isCmpListAdded = false;
    }
  }

  getCntList(contactId: any, i: any, companyId: any) {
    this.commonHelper.getCntList(contactId);

    const defaultCntList = this.commonHelper.defaultList.defaultSelectedCntList;
    const askWhereToSave: boolean = this.commonHelper.pluginSettings.askWhereToSave;

    if (defaultCntList !== undefined) {
      this.isCntListAdded.fill(false);
      this.isCntListAdded[i] = true;

      if (askWhereToSave) {
        this.commonHelper.selectedListType.selectedCntList = defaultCntList ? [defaultCntList] : [];
      } else {
        this.saveEmpContactList([defaultCntList], i, contactId, companyId);
        this.commonHelper.getEmployeeList(0, 5)
          .then();
      }
    } else {
      this.isCntListAdded.fill(false);
      this.isCntListAdded[i] = true;
    }

    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  saveEmpContactList(saveCntList: any, i: any, contactId: any, companyId: any) {

    if (this.commonHelper.defaultList.defaultSelectedCntList !== undefined) {
      this.isCntListAdded[i] = false;
    } else {
      this.isCntListAdded[i] = !this.isCntListAdded[i];
      if (this.cntListSelect) {
        this.cntListSelect.clearModel();
      }
    }

    if (saveCntList.length) {
      const body: any = {
        'indexIds': [contactId],
        'moduleType': 'mylist',
        'type': 'contact',
        'listName': saveCntList,
      };
      this.commonService.saveCntList(body)
        .then(() => {
          this.commonHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          this.onListCancel('contact');
          if (companyId) {
            this.commonHelper.getEmployeeList(0, 5)
              .then();
          }
        })
        .catch((_error: any) => {
          this.commonHelper.toastMessages(this.ls.data.contact.contactAlredyExist, 'error');
          this.onListCancel('contact');
        });
    }
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  saveCompanyList(saveCmpList: any) {
    this.isCmpListAdded = false;
    if (saveCmpList.length) {
      const body: any = {
        'indexIds': [this.commonHelper.pDetails.companyId],
        'moduleType': 'mylist',
        'type': 'account',
        'listName': saveCmpList,
      };
      this.commonService.saveCmpList(body)
        .then(() => {
          this.commonHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          this.onListCancel('company');
          this.commonHelper.getCompanyListSave(this.commonHelper.pDetails.companyId);
        })
        .catch((_error: any) => {
          this.commonHelper.toastMessages(this.ls.data.company.companyAlredyExist, 'error');
          this.onListCancel('company');
        });
    }
    if (this.cmpListSelect) {
      this.cmpListSelect.clearModel();
    }
  }

  addToList() {
    this.commonHelper.getCmpList(this.commonHelper.pDetails.companyId);
    const defaultList = this.commonHelper.defaultList.defaultSelectedCmpList;
    if (defaultList !== undefined) {
      if (this.commonHelper.pluginSettings.askWhereToSave) {
        this.isCmpListAdded = !this.isCmpListAdded;
        this.commonHelper.selectedListType.selectedCmpList = [defaultList];
      } else {
        this.saveCompanyList([defaultList]);
        this.commonHelper.getCompanyListSave(this.commonHelper.pDetails.companyId);
      }
    } else {
      this.isCmpListAdded = !this.isCmpListAdded;
    }

    if (this.cmpListSelect) {
      this.cmpListSelect.clearModel();
    }
  }

  handleContactView(contactId: any, toggleCollapse: boolean = false) {

    const handleData = (contactData: any) => {
      const previousContactData = this.contactData;
      this.contactData = contactData;
      if (previousContactData && previousContactData.contactId !== contactId) {
        const previousContact = this.commonHelper.employeeData.cntData.find((contact: any) => contact.contactId === previousContactData.contactId);
        if (previousContact) {
          previousContact.isContactCollapse = true;
          previousContact.alreadyView = true;
        }
      }
      this.commonHelper.employeeData.cntData.forEach((contact: any) => {
        contact.isContactCollapse = toggleCollapse ? contact.contactId === contactId ? !contact.isContactCollapse : false : contact.contactId === contactId;
        contact.alreadyView = contact.contactId === contactId ? true : contact.alreadyView;
      });
    };

    const handleError = (_error: any) => {
      this.commonHelper.toastMessages(this.ls.takeErrorMsg(_error?.error?.langCode), 'error');
    };

    this.commonService.contactView(contactId)
      .then(data => {
        handleData(data); // Call your success handler with the resolved data
      })
      .catch(error => {
        handleError(error); // Call your error handler with the caught error
      });
  }

  protected readonly goToExternalLink = goToExternalLink;
}

