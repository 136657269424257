import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalHelper {

  getActiveTabLinkedinUrl(tabUrl: string) {
    return new Promise((resolve, reject) => {
      try {
        const url = new URL(tabUrl);
        const searchParams = new URLSearchParams(url.search);
        let finalLinkedInUrl = '';

        if (searchParams.has('sessionRedirect')) {
          finalLinkedInUrl = searchParams.get('sessionRedirect')!;
        } else if (searchParams.has('session_redirect')) {
          finalLinkedInUrl = searchParams.get('session_redirect')!;
        } else {
          const path = url.pathname;
          const isProfilePath = path.match(/in\/([^\/]+)/);
          const isCompanyPath = path.match(/company\/([^\/]+)/);

          if (isProfilePath) {
            finalLinkedInUrl = `linkedin.com/in/${isProfilePath[1]}`;
          } else if (isCompanyPath) {
            finalLinkedInUrl = `linkedin.com/company/${isCompanyPath[1]}`;
          } else {
            const extractedDomain = tabUrl.match(/(?:https?:\/\/)?(?:www\.)?([^\/]+)\//);
            if (extractedDomain && extractedDomain[1]) {
              finalLinkedInUrl = extractedDomain[1];
            }
          }
        }

        if (!finalLinkedInUrl) {
          finalLinkedInUrl = tabUrl;
        }

        resolve({
          finalLinkedInUrl,
          isLinkedInUrl: finalLinkedInUrl.includes('linkedin.com'),
        });
      } catch {
        reject('');
      }
    });
  }

  getBrowserNameAndVersion() {
    let ua: string = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {
        name: 'IE',
        version: (tem[1] || ''),
      };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bEdg\/(\d+)/);
      if (tem != null) {
        return {
          name: 'MicroSoft Edge',
          version: tem[1],
        };
      }
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return {
          name: 'Opera',
          version: tem[1],
        };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

}
